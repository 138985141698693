import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import PlatformFeatures from "./components/Feature";
// import DigitalTwinViewerInsertion from "./components/DigitalTwinViewerInsertion";
import UserProjectsGrid from "./components/UserProjectsGrid";
import FileUpload from "./components/FileUpload";
import DigitalTwinViewerInsertion from "./components/DigitalTwinViewerInsertion";
import ProjectDetailPage from "./components/ProjectDetailPage";
import EnergyDataInsertion from "./components/EnergyDataInsertion";
import SceneSetupInsertion from "./components/SceneSetupInsertion";
import VirtualSensorComponent from "./components/VirtualSensorComponent";
import WindowSetup from "./components/WindowSetup";
import AssignToProject from "./components/AssignToProject";
import RegisterUser from "./components/RegisterUser";
import VirtualWindComponent from "./components/VirtualWindComponent";
import CrremComponent from "./components/CrremComponent";
import PVPanelSetup from "./components/PVPanelSetup";
import EnergyConsumptionDashboard from "./components/EnergyConsumptionDashboard";
import DayLightFactorSetup from "./components/DayLightFactorSetup";
import VirtualWholeSensorSetup from "./components/VirtualWholeSensorSetup";
import TechnicalData from "./components/TechnicalData";
import UserFiles from "./components/UserFiles";
import ThermalSe from "./components/ThermalSe";
import UserManagement from "./components/UserManagement";
import ProjectUserList from "./components/ProjectUserList";
import DeleteUserComponent from "./components/DeleteUserComponent";
import WithNavbar from "./components/LeftNavbar";
import ProjectManagement from "./components/ProjectManagement";
import NewProjectCreation from "./components/NewProjectCreation";
import ProjectEdit from "./components/ProjectEdit";
import NotificationBadge from "./components/NotificationBadge";
import WindRiskComponent from "./components/WindRiskComponent";
import RoomFootprint from "./components/RoomFootprint";
import CombinedComponent from "./components/CombinedComponent";
import ResetPassword from "./components/ResetPassword";
// http://127.0.0.1:8000/api/assets/22/get_asset_overview/

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/features" element={<PlatformFeatures />} />
        <Route path="/user-projects" element={<UserProjectsGrid />} />
        <Route
          path="/viewer-insertion"
          element={<DigitalTwinViewerInsertion />}
        />
        <Route path="/project/:projectId" element={<ProjectDetailPage />} />
        {/* <Route path="*" element={<div>404 Not Found</div>} /> */}
        <Route path="/file-upload" element={<FileUpload />} />
        <Route
          path="/energy-data/:projectId"
          element={<EnergyDataInsertion />}
        />
        <Route
          path="/scene-setup/:projectId"
          element={<SceneSetupInsertion />}
        />
        {/* <Route
          path="/virtual-sensor-setup"
          element={<VirtualSensorComponent />}
        /> */}
        <Route path="/window-setup/:projectId" element={<WindowSetup />} />
        <Route path="/assign-projects" element={<AssignToProject />} />

        {/* <Route path="/virtual-wind-setup" element={<VirtualWindComponent />} /> */}
        <Route
          path="/crrem-data-setup/:projectId"
          element={<CrremComponent />}
        />
        <Route path="/pv-panel-setup/:projectId" element={<PVPanelSetup />} />
        <Route
          path="/daylight-setup/:projectId"
          element={<DayLightFactorSetup />}
        />
        <Route
          path="/energy-dashboard-setup/:projectId"
          element={<EnergyConsumptionDashboard />}
        />
        <Route
          path="/virtual-sensor-setup/:projectId"
          element={<VirtualWholeSensorSetup />}
        />
        <Route path="/technical-data/:projectId" element={<TechnicalData />} />
        <Route path="/pure-file-setup/:projectId" element={<UserFiles />} />
        {/* <Route path="/user-management" element={<UserManagement />} /> */}
        <Route path="/project-user-relation" element={<ProjectUserList />} />
        <Route path="/thermal-se" element={<ThermalSe />} />
        <Route path="/notification" element={<NotificationBadge />} />
        <Route path="/delete-user" element={<DeleteUserComponent />} />
        <Route path="/wind-risk/:projectId" element={<WindRiskComponent />} />
        <Route path="/room-footprint/:projectId" element={<RoomFootprint />} />
        <Route path="/room-new/:projectId" element={<CombinedComponent />} />
        <Route
          path="/user-management"
          element={
            <WithNavbar>
              <UserManagement />
            </WithNavbar>
          }
        />
        <Route
          path="/user-registeration"
          element={
            <WithNavbar>
              <RegisterUser />
            </WithNavbar>
          }
        />

        <Route
          path="/password-reset/:user_id"
          element={
            <WithNavbar>
              <ResetPassword />
            </WithNavbar>
          }
        />
        <Route
          path="/project-management"
          element={
            <WithNavbar>
              <ProjectManagement />
            </WithNavbar>
          }
        />
        <Route
          path="/new-project"
          element={
            <WithNavbar>
              <NewProjectCreation />
            </WithNavbar>
          }
        />
        <Route
          path="/project-edit/:projectId"
          element={
            <WithNavbar>
              <ProjectEdit />
            </WithNavbar>
          }
        />
        <Route
          path="/virtual-wind/:projectId"
          element={
            <WithNavbar>
              <VirtualWindComponent />
            </WithNavbar>
          }
        />
        <Route
          path="/energy-dashboard-setup/:projectId"
          element={
            <WithNavbar>
              <EnergyConsumptionDashboard />
            </WithNavbar>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
