import React from "react";

const CustomFileUpload = ({ label, onFileSelect }) => {
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      onFileSelect(selectedFile);
    }
  };

  return (
    <div className="custom-file-upload">
      <label>{label}</label>
      <input type="file" onChange={handleFileChange} />
    </div>
  );
};

export default CustomFileUpload;
