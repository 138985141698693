import "./UserProject.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TopNavbar from "./TopNavbar";
import WithNavbar from "./LeftNavbar";
// var dispatch = useDispatch();
const Project = ({ name, description, imageUrl, id }) => {
  var navigate = useNavigate();
  const handleProjectClick = () => {
    localStorage.setItem("projectID", id);
    console.log(localStorage);
    navigate(`/project-edit/${id}`);
    // navigate(`/project/${id}`);
  };
  return (
    <div className="project" onClick={handleProjectClick}>
      <img src={imageUrl} alt={name} />
      <h3>{name}</h3>
      <p>{description}</p>
      <button className="edit-button">Edit</button>
    </div>
  );
};

const UserProjectsGrid = ({}) => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        console.log(token);
        const response = await fetch(
          `${apiRoot}/api/assets/${userId}/get_asset_overview/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setProjects(data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);
  return (
    <div>
      <WithNavbar />
      <TopNavbar />
      {/* <div className="navbar">
        <h1>User Projects</h1>
      </div> */}
      <div className="grid-container">
        {projects && projects.length > 0 ? (
          projects.map((project, index) => (
            <Project
              key={index}
              name={project.name}
              // description={project.description}
              imageUrl={project.image_url_value}
              id={project.id}
            />
          ))
        ) : (
          <p>No projects available.</p>
        )}
      </div>
    </div>
  );
};

export default UserProjectsGrid;
