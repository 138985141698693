import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./AssignToProject.css";
import WithNavbar from "./LeftNavbar";
import TopNavbar from "./TopNavbar";

const AssignToProject = () => {
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchType, setSearchType] = useState("id");
  const token = localStorage.getItem("token");
  const apiRootSecond = process.env.REACT_APP_API_ROOT;
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;

  useEffect(() => {
    fetchUsers();
    fetchProjects();
  }, []);

  useEffect(() => {
    fetchProjects();
  }, [searchType]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${apiRoot}/api/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      const formattedUsers = data.map((user) => ({
        value: user.id,
        label: user.email,
      }));
      setUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(`${apiRootSecond}/api/projects/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      const formattedProjects =
        searchType === "id"
          ? data.map((project) => ({
              value: project.id,
              label: project.id.toString(),
            }))
          : data.map((project) => ({
              value: project.id,
              label: project.name,
            }));

      setProjects(formattedProjects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
    setSelectedProject(null);
  };

  const handleAssign = async () => {
    const userIds = selectedUser.map((user) => user.value);
    const projectIds = selectedProject.map((project) => project.value);

    const bulkAssignments = userIds.map((userId) => {
      return projectIds.map((projectId) => ({
        user: userId,
        project: projectId,
      }));
    });

    try {
      const response = await fetch(`${apiRootSecond}/api/project_assignment/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bulkAssignments.flat()),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      alert("Users successfully assigned to projects.");
      setSelectedUser(null);
      setSelectedProject(null);
    } catch (error) {
      console.error("Error during assignment:", error);
      alert("Failed to assign users to projects.");
    }
  };

  return (
    <>
      <WithNavbar />
      <TopNavbar />
      <div className="assign-to-project-container">
        <h2 className="assign-to-project-heading">Assign User to Project</h2>

        <div className="search-type-container">
          <label>Search by:</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                value="id"
                checked={searchType === "id"}
                onChange={handleSearchTypeChange}
              />
              Project ID
            </label>
            <label>
              <input
                type="radio"
                value="name"
                checked={searchType === "name"}
                onChange={handleSearchTypeChange}
              />
              Project Name
            </label>
          </div>
        </div>

        <div className="assign-to-project-dropdown">
          <label className="assign-to-project-label">Project:</label>
          <Select
            options={projects}
            value={selectedProject}
            onChange={setSelectedProject}
            isMulti
            placeholder={`Select a project by ${
              searchType === "id" ? "ID" : "Name"
            }`}
          />
        </div>

        {/* User Dropdown */}
        <div className="assign-to-project-dropdown">
          <label className="assign-to-project-label">User:</label>
          <Select
            options={users}
            value={selectedUser}
            onChange={setSelectedUser}
            isMulti
            placeholder="Select a user"
          />
        </div>

        <button className="assign-to-project-button" onClick={handleAssign}>
          Assign
        </button>
      </div>
    </>
  );
};

export default AssignToProject;
