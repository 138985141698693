import React, { useState } from "react";
import "./FileUpload.css";
import ProjectDetailsInput from "./ProjectDetailsInput";
import FileUploadSingleComponent from "./FileUploadSingleComponent";
import Sidebar from "./Sidebar";

const FileUpload = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const [projectDetails, setProjectDetails] = useState({
    projectName: "",
    projectAddress: "",
    constructionYear: "",
  });

  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails({ ...projectDetails, [name]: value });
  };

  const projectFields = [
    { label: "Project Name", name: "projectName", className: "narrow" },
    { label: "Construction year", name: "projectName", className: "narrow" },
    {
      label: "Modernization year",
      name: "Modernization year",
      className: "narrow",
    },
    {
      label: "Type",
      name: "Type",
      type: "select",
      className: "narrow",
      options: ["Commercial", "Residential", "Industrial"],
    },
    { label: "Gross Asset Size", name: "projectName", className: "narrow" },
    { label: "Asset ID", name: "projectName", className: "narrow" },
    { label: "Address", name: "projectAddress", className: "wide" },
  ];

  const [floorOptions, setFloorOptions] = useState([]);

  const handleZipContentDetected = (fileNames) => {
    const floorNames = extractFloorNames(fileNames);
    setFloorOptions([...new Set(floorNames)]);
  };

  const extractFloorNames = (fileNames) => {
    const floorNameRegex = /[^\/]+\/([^\/_]+)_/;

    return fileNames
      .map((fileName) => {
        if (fileName === ".") {
          return null;
        }
        const match = floorNameRegex.exec(fileName);
        return match ? match[1] : null;
      })
      .filter((floorName, index, self) => {
        return floorName && self.indexOf(floorName) === index;
      });
  };
  const [availableFloors, setAvailableFloors] = useState([]);
  const [orderedFloors, setOrderedFloors] = useState([]);
  const addToOrderedFloors = (floor) => {
    if (!orderedFloors.includes(floor)) {
      setOrderedFloors([...orderedFloors, floor]);
      setAvailableFloors(availableFloors.filter((f) => f !== floor));
    }
  };

  const removeFromOrderedFloors = (floor) => {
    setOrderedFloors(orderedFloors.filter((f) => f !== floor));
    setAvailableFloors([...availableFloors, floor]);
  };

  const [selectedFloor, setSelectedFloor] = useState("");

  const [excelColumnNames, setExcelColumnNames] = useState([]);

  const handleEnergyZipContentDetected = (columnNames) => {
    setExcelColumnNames((prevNames) => {
      const allNames = new Set([...prevNames, ...columnNames]);
      return Array.from(allNames);
    });
  };

  const [activeSection, setActiveSection] = useState("section1"); // "section1" or "section2"

  return (
    <div>
      <div className="navbar">
        <h1>Digital Twin Platform</h1>
        <button onClick={() => window.history.back()}>Back to Features</button>
      </div>
      <Sidebar />

      {/* <Sidebar /> */}
      <div className="upload-container">
        <h2>Digital Twin Viewer Insertion</h2>

        <form onSubmit={handleSubmit}>
          <div>
            <h1>set project generic information</h1>
          </div>

          <div className="file-upload-class">
            <h1>Upload Project Files</h1>
            <FileUploadSingleComponent
              label="Scene Setup (ZIP), (Put All Of Your GLB Files In Single ZIP)"
              id="scene-setup"
            />
            <FileUploadSingleComponent
              label="Wind Sensors Setup"
              id="wind-setup"
            />
            <FileUploadSingleComponent
              label="Window Polygon Setup"
              id="window-polygon"
            />

            {/* <h1>Project File Upload</h1> */}
            <FileUploadSingleComponent
              label="Energy Data Setup"
              id="project-platform"
              isZip={true}
              onZipContentDetected={handleEnergyZipContentDetected}
              energyData={true}
            />
            {excelColumnNames.length > 0 && (
              <div>
                <h2>Extracted Column Names:</h2>
                <ul>
                  {excelColumnNames.map((name, index) => (
                    <li key={index}>{name}</li>
                  ))}
                </ul>
              </div>
            )}

            <div className="floors-management-container">
              <div className="floors-list">
                <h2>Available Energy Values:</h2>
                <select multiple size="10" className="textarea-class">
                  {excelColumnNames.map((floor, index) => (
                    <option
                      key={index}
                      value={floor}
                      onDoubleClick={() => addToOrderedFloors(floor)}
                    >
                      {floor}
                    </option>
                  ))}
                </select>
              </div>
              <div className="floor-buttons">
                <button
                  type="button"
                  onClick={() => {
                    const selectElement = document.querySelector(
                      ".floors-list select"
                    );
                    if (selectElement.selectedOptions.length > 0) {
                      const selectedFloor =
                        selectElement.selectedOptions[0].value;
                      addToOrderedFloors(selectedFloor);
                    }
                  }}
                >
                  Add →
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const selectElement = document.querySelector(
                      ".ordered-floors-list select"
                    );
                    if (selectElement.selectedOptions.length > 0) {
                      const selectedFloor =
                        selectElement.selectedOptions[0].value;
                      removeFromOrderedFloors(selectedFloor);
                    }
                  }}
                >
                  ← Remove
                </button>
              </div>
              <div className="floors-list ordered-floors-list">
                <h2>Ordered Floors:</h2>
                <select multiple size="10" className="textarea-class">
                  {orderedFloors.map((floor, index) => (
                    <option
                      key={index}
                      value={floor}
                      onDoubleClick={() => removeFromOrderedFloors(floor)}
                    >
                      {floor}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <FileUploadSingleComponent
              label="Scene Setup"
              id="project-platform"
              isZip={true}
              onZipContentDetected={handleZipContentDetected}
            />
            <div className="floors-management-container">
              <div className="floors-list">
                <h2>Available Floors:</h2>
                <select multiple size="10" className="textarea-class">
                  {console.log(floorOptions)}
                  {floorOptions.map((floor, index) => (
                    <option
                      key={index}
                      value={floor}
                      onDoubleClick={() => addToOrderedFloors(floor)}
                    >
                      {floor}
                    </option>
                  ))}
                </select>
              </div>
              <div className="floor-buttons">
                <button
                  type="button"
                  onClick={() => {
                    const selectElement = document.querySelector(
                      ".floors-list select"
                    );
                    if (selectElement.selectedOptions.length > 0) {
                      const selectedFloor =
                        selectElement.selectedOptions[0].value;
                      addToOrderedFloors(selectedFloor);
                    }
                  }}
                >
                  Add →
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const selectElement = document.querySelector(
                      ".ordered-floors-list select"
                    );
                    if (selectElement.selectedOptions.length > 0) {
                      const selectedFloor =
                        selectElement.selectedOptions[0].value;
                      removeFromOrderedFloors(selectedFloor);
                    }
                  }}
                >
                  ← Remove
                </button>
              </div>
              <div className="floors-list ordered-floors-list">
                <h2>Ordered Floors:</h2>
                <select multiple size="10" className="textarea-class">
                  {orderedFloors.map((floor, index) => (
                    <option
                      key={index}
                      value={floor}
                      onDoubleClick={() => removeFromOrderedFloors(floor)}
                    >
                      {floor}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div>
            <h2>please insert your floor order</h2>
          </div>

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default FileUpload;
