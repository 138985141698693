import React from "react";
import "./RegisterUser.css";
import "./Features.css";
const TopNavbar = () => {
  const topNavbarStyle = {
    position: "fixed",
    top: 0,
    left: "0vw",
    width: "100vw",
    height: "10vh",
    backgroundColor: "#2c3e50",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1000,
  };

  const navItemStyle = {
    textDecoration: "none",
    color: "white",
    padding: "0 15px",
  };

  return (
    <div style={topNavbarStyle}>
      <h1 style={{ marginLeft: "10px" }}>Digital Twin Platform</h1>
      <ul style={{ listStyleType: "none", display: "flex", margin: 0 }}>
        <li>
          <a href="#features" style={navItemStyle}>
            Features
          </a>
        </li>
        <li>
          <a href="#activity-report" style={navItemStyle}>
            Activity Report
          </a>
        </li>
      </ul>
    </div>
  );
};
export default TopNavbar;
