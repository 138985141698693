import React, { useState } from "react";
import "./FileUpload.css";
import JSZip from "jszip";
import { read, utils } from "xlsx";

const FileUploadSingleComponentEnergyData = ({
  label,
  id,
  isZip,
  onZipContentDetected,
  energyData,
  onFileSelect,
}) => {
  const [fileName, setFileName] = useState("No file chosen");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      onFileSelect(id, file);

      if (isZip && energyData) {
        processEnergyDataZipFile(file, onZipContentDetected);
      } else if (isZip) {
        processZipFile(file, onZipContentDetected);
      }
    } else {
      setFileName("No file chosen");
    }
  };

  const processZipFile = (file) => {
    const zip = new JSZip();
    zip
      .loadAsync(file)
      .then((contents) => {
        const fileNames = Object.keys(contents.files);
        console.log("fdfdfd");
        console.log(fileNames);
        onZipContentDetected(fileNames);
      })
      .catch((error) => {
        console.error("Error reading ZIP file: ", error);
      });
  };
  const processEnergyDataZipFile = (file, onZipContentDetected) => {
    const zip = new JSZip();
    zip
      .loadAsync(file)
      .then(async (contents) => {
        const columnNamesArray = [];
        const xlsxFileNames = [];

        for (const fileName of Object.keys(contents.files)) {
          if (fileName.includes("__MACOSX") || fileName.includes(".DS_Store")) {
            continue;
          }
          if (fileName.endsWith(".xlsx")) {
            xlsxFileNames.push(fileName);

            const excelFile = await contents.files[fileName].async(
              "arraybuffer"
            );
            const workbook = read(excelFile, { type: "array" });

            workbook.SheetNames.forEach((sheetName) => {
              const sheet = workbook.Sheets[sheetName];
              const firstRow = utils.sheet_to_json(sheet, {
                header: 1,
                range: 0,
              })[0];
              if (firstRow) {
                const filteredRow = firstRow.filter(
                  (columnName) =>
                    ![
                      "Name",
                      "Area [m2]",
                      "Level",
                      "Function",
                      "Middle room",
                      "Heatmaps X coordinates",
                      "Heatmaps Y coordinates",
                      "Room elevation",
                    ].includes(columnName)
                );
                columnNamesArray.push(...filteredRow);
              }
            });
          }
        }
        const flattenedColumnNames = columnNamesArray.flat();

        if (onZipContentDetected) {
          onZipContentDetected(flattenedColumnNames, xlsxFileNames);
        }
      })
      .catch((error) => {
        console.error("Error reading ZIP file: ", error);
      });
  };

  return (
    <div className="file-upload">
      <label htmlFor={id}>{label}</label>
      <div className="file-upload-wrapper">
        <input
          type="file"
          id={id}
          onChange={handleFileChange}
          accept={isZip ? ".zip" : undefined}
        />
        <label className="file-upload-label" htmlFor={id}>
          Choose File
        </label>
        <span
          className="file
-name"
        >
          {fileName}
        </span>
      </div>
    </div>
  );
};

export default FileUploadSingleComponentEnergyData;
