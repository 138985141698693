import React, { useState } from "react";
import "./LoginPage.css";
import { useNavigate } from "react-router-dom";

const LoginPage = ({ onAuthenticated }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
    if (!apiRoot) {
      console.error("API root is not set in the environment variables");
      return;
    }

    const tokenEndpoint = `${apiRoot}/api/login`;
    console.log(tokenEndpoint);
    const client_id = "vue-frontend";
    const client_secret = "";

    try {
      const response = await fetch(tokenEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: `grant_type=password&username=${encodeURIComponent(
          username
        )}&password=${encodeURIComponent(
          password
        )}&client_id=${client_id}&client_secret=${client_secret}`,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.access_token) {
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("userID", data.user_id);
        localStorage.setItem("role", data.role);

        navigate("/features");
        onAuthenticated(data.access_token);
      } else {
        console.error("Authentication failed:", data);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <div className="login-page">
      <div className="background-animation"></div>
      <div className="login-container">
        <h2>Welcome to the Digital Twin Portal Setup</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
        <p className="footer">© 2024 Digital Twin Technologies</p>
      </div>
    </div>
  );
};

export default LoginPage;
