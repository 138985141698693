import React from "react";
import "./Features.css";
import fetchUtil from "./utils/fetchUtil";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopNavbar from "./TopNavbar";

const Navbar = () => {
  return (
    <div className="navbar">
      <h1>Digital Twin Platform</h1>
      <ul>
        <li>
          <a href="#features">Features</a>
        </li>
        <li>
          <a href="#activity-report">Activity Report</a>
        </li>
      </ul>
    </div>
  );
};

const Feature = ({ title, description, onClick }) => {
  return (
    <div className="feature" onClick={onClick}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};
const Features = () => {
  const features = [
    // {
    //   title: "CREATE NEW DIGITAL TWIN PROJECT",
    //   description:
    //     "Here, you can create a project for platform or digital twin viewer",
    //   path: "viewer-insertion",
    // },
    // {
    //   title: "EDIT EXISTING DIGITAL TWIN PROJECT",
    //   description:
    //     "Here, you can edit an exiting project for platform or digital twin viewer",
    //   path: "user-projects",
    // },
    {
      title: "Project Managment",
      description: "You can create new project or edit existing project",
      path: "project-management",
    },
    {
      title: "USER MANAGEMENT",
      description:
        "You can create your own user with dedicated username and password.",
      path: "user-management",
    },
  ];

  const navigate = useNavigate();

  const handleFeatureClick = (feature) => {
    navigate(feature);
  };

  return (
    <div className="container" id="features">
      <h2>Platform Features</h2>
      <div className="features">
        {features.map((feature, index) => (
          <Feature
            key={index}
            title={feature.title}
            description={feature.description}
            onClick={() => handleFeatureClick(`/${feature.path}`)}
          />
        ))}
      </div>
    </div>
  );
};

const ActivityReport = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const [reportRows, setReportRows] = useState([]);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userID");
  const projectID = localStorage.getItem("projectID");

  useEffect(() => {
    const fetchActivityReport = async () => {
      try {
        const data = await fetchUtil(
          `${apiRoot}/api/report/?project_id=${projectID}&user_id=${userId}`,
          "GET",
          token
        );
        const sortedData = data
          .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
          .slice(0, 30);
        setReportRows(sortedData);
      } catch (error) {
        console.error("Failed to fetch activity report:", error);
      }
    };

    if (token) {
      fetchActivityReport();
    }
  }, [token]);

  return (
    <div className="container" id="activity-report">
      <h2>Last Activity Report</h2>
      <div className="activity-report">
        <table>
          <thead>
            <tr>
              <th>Action</th>
              <th>Status</th>
              <th>Description</th>
              <th>Project Name</th>
              <th>Feature</th>
              <th>Datetime</th>
            </tr>
          </thead>
          <tbody>
            {reportRows.map((row, index) => (
              <tr key={index}>
                <td>{row.action_type}</td>
                <td>{row.status_code}</td>
                <td>{row.description}</td>
                <td>{row.project}</td>
                <td>{/* Feature data if available */}</td>
                <td>{row.datetime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PlatformFeatures = () => {
  const user = null;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  if (!token) {
    console.log("nadarad");
    return <p>Please login to view this page.</p>;
  }
  console.log(token);

  return (
    <div>
      <TopNavbar />
      {/* <Navbar /> */}
      <Features />
      <ActivityReport />
    </div>
  );
};

export default PlatformFeatures;
