import React from "react";
import FileSingleComponent from "./FileSingleComponent";

const PVPanelSetup = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  return (
    <div>
      <FileSingleComponent
        heading1="Upload Your PV Panel Zip File"
        heading2="Digital Twin File Uploader"
        fileUploadComponents={[
          {
            label: "PV PANEL  EXCEL FILE",
            id: "scene-setup",
            uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=pv`,
          },
          // {
          //   label: "DAYLIGHT EXCEL FILE",
          //   id: "scene-setup",
          //   uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=daylight`,
          // },
        ]}
      />
    </div>
  );
};

export default PVPanelSetup;
