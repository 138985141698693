import React from "react";
import "./ProjectDetailsInput.css";

const ProjectDetailsInput = ({
  label,
  name,
  value,
  onChange,
  type,
  className,
  options,
}) => {
  return (
    <div className={`project-details-input ${className}`}>
      <label htmlFor={name}>{label}</label>
      {type === "select" ? (
        <select id={name} name={name} value={value} onChange={onChange}>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : (
        <input
          id={name}
          type="text"
          name={name}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default ProjectDetailsInput;
