import React from "react";
import "./FileUpload.css";
import { Link } from "react-router-dom";
const Sidebar = () => {
  const projectId = localStorage.getItem("projectID");
  const createLinkTo = (path) => {
    return projectId ? `${path}/${projectId}` : path;
  };
  const hoverTexts = {
    "/scene-setup": "Setup your scene here",
    "/energy-data": "Configure energy data",
    "/virtual-sensor-setup": "Setup virtual sensors",
    "/window-setup": "Manage window settings",
    "/crrem-data-setup": "Setup CRREM data",
    "/pv-panel-setup": "Configure PV panel settings",
    "/energy-dashboard-setup": "Setup your energy dashboard",
    "/features": "Go to the main menu",
    "/pure-file-setup": "",
  };
  return (
    <div className="sidebar">
      <h1>Edit INFO </h1>

      <Link to={createLinkTo("/scene-setup")}>Scene Setup</Link>
      <Link to={createLinkTo("/energy-data")}>Energy Data Setup</Link>
      <Link to="/virtual-sensor-setup">Virtual Sensors Setup</Link>
      <Link to="/window-setup">Window Setup</Link>
      <Link to="/crrem-data-setup">Crrem data Setup</Link>
      <Link to="/pv-panel-setup">PV / Daylight Setup</Link>
      <Link to="/energy-dashboard-setup">Energy Dashboard Setup</Link>
      <Link to="/pure-file-setup">File Setup</Link>
      <Link to="/features">Main Menue</Link>
      {/* <Link to="/thermal-se">thermal2</Link> */}
    </div>
  );
};

export default Sidebar;
