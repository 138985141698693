import React from "react";
import { Link } from "react-router-dom";

const LeftNavbar = () => {
  return (
    <div
      style={{
        width: "10vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "#333",
        color: "white",
        padding: "20px",
        zIndex: 1000,
      }}
    >
      <h3>Site Menu</h3>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        <li style={{ marginBottom: "40px" }}>
          <Link to="/" style={{ color: "white", textDecoration: "none" }}>
            Home
          </Link>
        </li>

        <li style={{ marginBottom: "40px" }}>
          <Link
            to="/features"
            style={{ color: "white", textDecoration: "none" }}
          >
            Notification
          </Link>
        </li>
        <li style={{ marginBottom: "40px" }}>
          <Link
            to="/user-management"
            style={{ color: "white", textDecoration: "none" }}
          >
            User Management
          </Link>
        </li>
        <li style={{ marginBottom: "40px" }}>
          <Link
            to="/project-management"
            style={{ color: "white", textDecoration: "none" }}
          >
            Project Management
          </Link>
        </li>
      </ul>
    </div>
  );
};

const WithNavbar = ({ children }) => {
  return (
    <div>
      <LeftNavbar />
      <div style={{ marginLeft: "200px" }}>{children}</div>
    </div>
  );
};

export default WithNavbar;
