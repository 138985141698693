import React, { useState, useEffect } from "react";
import WindowUploadFileComponent from "./WindowUploadFileComponent";

const WindowSetup = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const apiRootSecond = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  const [floorList, setFloorList] = useState([]);
  const [maxItems, setMaxItems] = useState(null);
  useEffect(() => {
    const fetchProjectInfo = async () => {
      const response = await fetch(
        `${apiRootSecond}/api/project_info/?project_id=${projectID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        console.error("Failed to fetch project info:", response.statusText);
        return;
      }
      const data = await response.json();
      if (data.length > 0) {
        setFloorList(JSON.parse(data[0].floor_list));
      }
    };
    const fetchSurfaceInfo = async () => {
      const response = await fetch(
        `${apiRoot}/api/surfaces?projectId=${projectID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        console.error("Failed to fetch surface info:", response.statusText);
        return;
      }
      const data = await response.json();
      setMaxItems(data.maxItems);
    };

    fetchProjectInfo();
    fetchSurfaceInfo();
  }, [apiRoot, projectID, token]);

  return (
    <div>
      <WindowUploadFileComponent
        heading1="Upload Your Window Zip File"
        heading2="Digital Twin File Uploader"
        fileUploadComponents={[
          {
            label: "Window Zip File",
            id: "scene-setup",
            uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=window`,
          },
        ]}
        apiText="Here is the floor list available on DB. Please ensure your windows file name contains the floor name."
        apiContextArg={floorList}
        noWindows={maxItems}
      />
    </div>
  );
};

export default WindowSetup;
