import React from "react";
import FileSingleComponent from "./FileSingleComponent";
import { useState } from "react";
import { useEffect } from "react";

const VirtualWholeSensorSetup = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  const [sensorsCount, setSensorsCount] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${apiRoot}/api/sensorslight/count/?project_id=${projectID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        console.error("Failed to fetch data:", response.statusText);
        return;
      }
      const data = await response.json();
      console.log(data);
      // if (data.length > 0) {
      //   setSensorsCount(JSON.parse(data[0].count));
      // }
      console.log("jaljfljal");
      console.log(setSensorsCount(data.count));
    };

    fetchData();
  }, [apiRoot, projectID, token]);
  return (
    <div>
      <FileSingleComponent
        heading1="Upload Your Project Virtual Wind and Virtual Sensor"
        heading2="Digital Twin File Uploader"
        fileUploadComponents={[
          {
            label:
              "Upload Your virtual sensor data here, Please Upload a CSV file",
            id: "sensor-setup",
            uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=virtual_sensor`,
          },
        ]}
        apiText="number of sensors available for this project is:"
        apiContextArg={sensorsCount}
      />
    </div>
  );
};

export default VirtualWholeSensorSetup;
