import React, { useState, useEffect } from "react";
import "./ProjectUserList.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WithNavbar from "./LeftNavbar";
import TopNavbar from "./TopNavbar";

const ProjectUserList = () => {
  const [expandedUserId, setExpandedUserId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const toggleExpandUser = (userId) => {
    setExpandedUserId(expandedUserId === userId ? null : userId);
  };

  // Function to fetch users
  const fetchUsers = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${apiRoot}/api/project_assignment/user_projects/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch: " + response.statusText);
      }

      const data = await response.json();
      setUsers(data.filter((user) => user.projects.length > 0));
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const usersToShow =
    searchTerm.length >= 3
      ? users.filter((user) =>
          user.projects.some((projectName) =>
            projectName.toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      : [];

  const revokeAccess = async (userName, projectName) => {
    console.log(userName);
    try {
      const response = await fetch(
        `${apiRoot}/api/project_assignment/revoke_project_access/?user=${userName}&project=${projectName}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({
          //   user: userName,
          //   project: projectName,
          // }),
        }
      );

      // if (!response.ok) {
      //   throw new Error("Failed to revoke access: " + response.statusText);
      // }
      if (response.status === 204) {
        toast.success(`Access revoked for ${userName} on ${projectName}`);
        // alert(`Access revoked for ${userName} on ${projectName}`);
        fetchUsers();
        return;
      }

      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      } else {
        toast.success(`Access revoked for ${userName} on ${projectName}`);
        // alert(`Access revoked for ${userName} on ${projectName}`);
        fetchUsers();
      }
    } catch (err) {
      toast.error(`Failed to revoke access: ${err.message}`);
    }
  };

  return (
    <>
      <WithNavbar />
      <TopNavbar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container-user-list">
        <input
          type="text"
          placeholder="Type at least 3 characters to search projects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {usersToShow.map((user) => (
          <div
            key={user.id}
            className={`user-block ${
              expandedUserId === user.id ? "expanded" : ""
            }`}
          >
            <div
              className="user-header"
              onClick={() => toggleExpandUser(user.id)}
            >
              {user.name}
              <span
                className={`arrow ${
                  expandedUserId === user.id ? "expanded" : ""
                }`}
              >
                &#9656;
              </span>
            </div>
            <div className="project-container-user-list">
              {user.projects.map((project, index) => (
                <span key={index} className="project-user-list">
                  {project}

                  <button
                    title="Revoke access"
                    onClick={() => revokeAccess(user.id, project)}
                  >
                    &#10005;
                  </button>
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProjectUserList;
