import React from "react";
import FileSingleComponent from "./FileSingleComponent";
import { useState } from "react";

const UserFiles = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  return (
    <div>
      <FileSingleComponent
        heading1="Upload Your Files here"
        heading2="Digital Twin File Uploader"
        fileUploadComponents={[
          {
            label: "Upload Your EPC File",
            id: "epc-file-setup",
            uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=epc`,
          },
          {
            label: "Upload Your Certificate File",
            id: "certificate-file-setup",
            uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=certificate`,
          },
          {
            label: "Upload Building Profile File",
            id: "building-profile-setup",
            uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=user_files`,
          },
        ]}
        // showEdit="true"
      />
    </div>
  );
};

export default UserFiles;
