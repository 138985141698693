import React, { useState } from "react";
import "./FileUpload.css";
import JSZip from "jszip";
import { read, utils } from "xlsx";

const FileUploadSingleComponent = ({
  label,
  id,
  isZip,
  onZipContentDetected,
  energyData,
  onFileSelect,
}) => {
  const [fileName, setFileName] = useState("No file chosen");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      onFileSelect(file);

      if (isZip) {
        processZipFile(file);
      }
    } else {
      setFileName("No file chosen");
    }
  };

  const processZipFile = (file) => {
    const zip = new JSZip();
    zip
      .loadAsync(file)
      .then((contents) => {
        const fileNames = Object.keys(contents.files);
        console.log("fdfdfd");
        console.log(fileNames);
        onZipContentDetected(fileNames);
      })
      .catch((error) => {
        console.error("Error reading ZIP file: ", error);
      });
  };

  return (
    <div className="file-upload">
      <label htmlFor={id}>{label}</label>
      <div className="file-upload-wrapper">
        <input
          type="file"
          id={id}
          onChange={handleFileChange}
          accept={isZip ? ".zip" : undefined}
        />
        <label className="file-upload-label" htmlFor={id}>
          Choose File
        </label>
        <span
          className="file
-name"
        >
          {fileName}
        </span>
      </div>
    </div>
  );
};

export default FileUploadSingleComponent;
