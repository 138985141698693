import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import "./RegisterUser.css";
import TopNavbar from "./TopNavbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
  const { userId } = useParams();
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${apiRoot}/api/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      const formattedUsers = data.map((user) => ({
        value: user.id,
        label: user.email,
      }));
      setUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const resetPassword = async (userData) => {
    const formData = new FormData();
    formData.append("newPassword", userData.newPassword);
    formData.append("confirmPassword", userData.confirmPassword);

    try {
      const response = await fetch(
        `${apiRoot}/api/user/${userData.userId}/reset-password`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to reset password");
      }

      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedUser) {
      setMessage("Please select a user");
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    try {
      const data = await resetPassword({
        userId: selectedUser.value,
        newPassword,
        confirmPassword,
      });

      console.log("Password reset successful", data);

      toast.success("Password reset successfully!");

      setNewPassword("");
      setConfirmPassword("");
      setSelectedUser(null);
      setMessage("");
    } catch (error) {
      toast.error("Password reset failed: " + error.message);
      setMessage(error.message || "Failed to reset password");
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <TopNavbar />
      <div className="register-container">
        {message && (
          <div
            className={`message ${
              newPassword !== confirmPassword
                ? "error-message"
                : "success-message"
            }`}
          >
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit} className="register-form">
          <div className="form-group">
            <label htmlFor="userSelect">Select User:</label>
            <Select
              id="userSelect"
              options={users}
              value={selectedUser}
              onChange={setSelectedUser}
              placeholder="Select a user"
            />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password:</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button type="submit">Reset Password</button>
        </form>
      </div>
    </>
  );
};

export default ResetPassword;
