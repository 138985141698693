import React, { useState } from "react";
import RoomFootprint from "./RoomFootprint";
import EnergyDataInsertion from "./EnergyDataInsertion";
import WithNavbar from "./LeftNavbar";
import TopNavbar from "./TopNavbar";
import "./CombinedComponent.css";

const CombinedComponent = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div>
      <WithNavbar />
      <TopNavbar />
      <div className="combined-container">
        <div className="options-container">
          <button
            className={`option-button ${
              selectedOption === "insertRoom" ? "active" : ""
            }`}
            onClick={() => handleOptionClick("insertRoom")}
          >
            Insert Room
          </button>
          <button
            className={`option-button ${
              selectedOption === "insertEnergyData" ? "active" : ""
            }`}
            onClick={() => handleOptionClick("insertEnergyData")}
          >
            Insert Energy Data
          </button>
        </div>

        <div className="content-container">
          {selectedOption === "insertRoom" && (
            <div className="component-section">
              <RoomFootprint />
            </div>
          )}
          {selectedOption === "insertEnergyData" && (
            <div className="component-section">
              <EnergyDataInsertion />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CombinedComponent;
