import React from "react";
import FileSingleComponent from "./FileSingleComponent";

const VirtualWindComponent = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  return (
    <div>
      <FileSingleComponent
        heading1="Upload Your Project Virtual Wind  Sensor"
        heading2="Digital Twin File Uploader"
        fileUploadComponents={[
          {
            label:
              "Upload Your virtual wind data here, Please upload a ZIP file",
            id: "wind-setup",
            uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=virtual_wind`,
          },
        ]}
        showEdit="false"
        editText="Edit or Insert Wind Direction order"
        showEditHover="Activate This when you want to insert the whole winds for the first time ,
        or you want to re-upload all your wind direction. Dont active this,
        when you want to upload one or two direction "
      />
    </div>
  );
};

export default VirtualWindComponent;
