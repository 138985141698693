// import React from "react";
import FileSingleComponent from "./FileSingleComponentCrrem";
import React, { useState } from "react";
import * as XLSX from "xlsx";

const ColumnMappingComponent = ({ onFileSelected, onMappingsConfirmed }) => {
  const [fileHeaders, setFileHeaders] = useState([]);
  const [mappings, setMappings] = useState({
    current_energy_consumption: "",
    crrem_energy_consumption: "",
    current_carbon_emission: "",
    crrem_carbon_emission: "",
    climate_changes_consumption: "",
    climate_changes_emission: "",
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
        setFileHeaders(headers);
        onFileSelected(file);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleMappingChange = (field) => (event) => {
    setMappings({ ...mappings, [field]: event.target.value });
  };

  const handleSubmit = () => {
    onMappingsConfirmed(mappings);
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} />
      {fileHeaders.length > 0 && (
        <div>
          <h3>Map your columns to the expected data fields:</h3>
          {Object.keys(mappings).map((key) => (
            <div key={key}>
              <label>{key.replace("_", " ").toUpperCase()}: </label>
              <select value={mappings[key]} onChange={handleMappingChange(key)}>
                <option value="">Select a column...</option>
                {fileHeaders.map((header, index) => (
                  <option key={index} value={header}>
                    {header}
                  </option>
                ))}
              </select>
            </div>
          ))}
          <button onClick={handleSubmit}>Confirm Mappings</button>
        </div>
      )}
    </div>
  );
};

const CrremComponent = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const projectID = localStorage.getItem("projectID");
  const userId = localStorage.getItem("userID");

  const fileUploadComponents = [
    {
      label: "Zip or Excel Crrem File",
      id: "crrem-file",
      uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=crrem`,
    },
  ];

  const showEdit = false;
  const editText = "Edit your submission";
  const showEditHover = "Check to edit the submission";

  return (
    <div>
      <FileSingleComponent
        heading1="Upload Your Crrem File Here"
        heading2="Please upload the Zip or Excel file"
        fileUploadComponents={fileUploadComponents}
        showEdit={showEdit}
        editText={editText}
        showEditHover={showEditHover}
      />
    </div>
  );
};

export default CrremComponent;
