import React, { useState } from "react";
import "./FileUpload.css";

const FileUploadWithoutFeatureSingleComponent = ({
  label,
  id,
  onFileSelect,
}) => {
  const [fileName, setFileName] = useState("No file chosen");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      onFileSelect(id, file);
    } else {
      setFileName("No file chosen");
    }
  };

  return (
    <div className="file-upload">
      <label htmlFor={id}>{label}</label>
      <div className="file-upload-wrapper">
        <input type="file" id={id} onChange={handleFileChange} />
        <label className="file-upload-label" htmlFor={id}>
          Choose File
        </label>
        <span className="file-name">{fileName}</span>
      </div>
    </div>
  );
};

export default FileUploadWithoutFeatureSingleComponent;
