import React, { useState } from "react";
import "./RegisterUser.css";
import "./Features.css";
import fetchUtil from "./utils/fetchUtil";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopNavbar from "./TopNavbar";

const Feature = ({ title, description, onClick }) => {
  return (
    <div className="feature" onClick={onClick}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};
const Features = () => {
  const userId = localStorage.getItem("userID");
  const features = [
    {
      title: "CREATE YOUR OWN USER",
      description:
        "You can create your own user with dedicated username and password.",
      path: "user-registeration",
    },

    {
      title: "ASSIGN PROJECT TO USERS",
      description: "here you can assign specific project to users.",
      path: "assign-projects",
    },
    {
      title: "DELETE USER",
      description: "Here, you can delete your specific user",
      path: "delete-user",
    },
    {
      title: "USERS'S PROJECTS",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",
      path: "project-user-relation",
    },
    {
      title: "RESET PASSWORD",
      description: "Here, you can reset password for your desired users",
      path: `password-reset/${userId}`,
    },
  ];

  const navigate = useNavigate();

  const handleFeatureClick = (feature) => {
    navigate(feature);
  };

  return (
    <div className="container" id="features">
      <h2>Platform Features</h2>
      <div className="features">
        {features.map((feature, index) => (
          <Feature
            key={index}
            title={feature.title}
            description={feature.description}
            onClick={() => handleFeatureClick(`/${feature.path}`)}
          />
        ))}
      </div>
    </div>
  );
};

const ActivityReport = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const [reportRows, setReportRows] = useState([]);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userID");
  const projectID = localStorage.getItem("projectID");

  useEffect(() => {
    const fetchActivityReport = async () => {
      try {
        const data = await fetchUtil(
          `${apiRoot}/api/report/?project_id=${projectID}&user_id=${userId}`,
          "GET",
          token
        );
        const sortedData = data
          .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
          .slice(0, 30);
        setReportRows(sortedData);
      } catch (error) {
        console.error("Failed to fetch activity report:", error);
      }
    };

    if (token) {
      fetchActivityReport();
    }
  }, [token]);

  return (
    <div className="container" id="activity-report">
      <h2>Last Activity Report</h2>
      <div className="activity-report">
        <table>
          <thead>
            <tr>
              <th>Action</th>
              <th>Status</th>
              <th>Description</th>
              <th>Project Name</th>
              <th>Feature</th>
              <th>Datetime</th>
            </tr>
          </thead>
          <tbody>
            {reportRows.map((row, index) => (
              <tr key={index}>
                <td>{row.action_type}</td>
                <td>{row.status_code}</td>
                <td>{row.description}</td>
                <td>{row.project}</td>
                <td></td>
                <td>{row.datetime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const UserManagement = () => {
  const user = null;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  if (!token) {
    console.log("nadarad");
    return <p>Please login to view this page.</p>;
  }
  console.log(token);

  return (
    <div>
      <TopNavbar />
      <Features />
      {/* <ActivityReport /> */}
    </div>
  );
};

export default UserManagement;
