// import React, { useState } from "react";
// import "./FileUpload.css";
// import ProjectDetailsInput from "./ProjectDetailsInput";
// import { useNavigate } from "react-router-dom";
// import TopNavbar from "./TopNavbar";
// import fetchUtil from "./utils/fetchUtil";
// import WithNavbar from "./LeftNavbar";
// const LoadingSpinner = () => <div className="spinner">Loading...</div>;

// const DigitalTwinViewerInsertion = () => {
//   const apiRoot = process.env.REACT_APP_API_ROOT;
//   const apiRootOperational = process.env.REACT_APP_API_ROOT_OPERATIONAL;
//   const [isLoading, setIsLoading] = useState(false);
//   const [projectImage, setProjectImage] = useState(null);
//   const [projectDetails, setProjectDetails] = useState({
//     constructionYear: "",
//     name: "",
//     type: "office",
//     lastModernized: "",
//     grossAssetSize: "",
//     descriptiveName: "",
//     assetId: "",
//     location: "",
//     address: "",
//     energyLabel: "",
//   });

//   const handleImageChange = (e) => {
//     setProjectImage(e.target.files[0]);
//   };

//   const token = localStorage.getItem("token");
//   const userID = localStorage.getItem("userID");
//   const navigate = useNavigate();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setIsLoading(true);

//     const formattedData = {
//       ...projectDetails,
//       constructionYear: projectDetails.construction_year
//         ? parseInt(projectDetails.construction_year)
//         : null,
//       lastModernized: projectDetails.last_modernized
//         ? parseInt(projectDetails.last_modernized)
//         : null,
//       grossAssetSize: projectDetails.gross_asset_size
//         ? parseInt(projectDetails.gross_asset_size)
//         : null,
//       processing: 0,
//       customerId: 1,
//     };

//     try {
//       const projectData = await fetchUtil(
//         `${apiRootOperational}/api/projects`,
//         "POST",
//         token,
//         formattedData
//       );
//       const projectID = projectData.id;

//       if (projectImage) {
//         const formData = new FormData();
//         formData.append("image_file", projectImage);
//         formData.append("project", projectID);

//         const imageUploadData = await fetchUtil(
//           `${apiRoot}/api/images/`,
//           "POST",
//           token,
//           formData,
//           true
//         );
//         console.log("Image upload successful", imageUploadData);
//       }

//       const userProjectAssignmentBody = {
//         project: projectID,
//         user: userID,
//       };
//       const userProjectAssignmentData = await fetchUtil(
//         `${apiRoot}/api/project_assignment/`,
//         "POST",
//         token,
//         userProjectAssignmentBody
//       );
//       console.log("Project assignment successful", userProjectAssignmentData);

//       setIsLoading(false);
//       navigate("/user-projects");
//     } catch (error) {
//       setIsLoading(false);
//       console.error("Error:", error.message);
//     }
//   };

//   const handleDetailChange = (e) => {
//     const { name, value } = e.target;
//     setProjectDetails({ ...projectDetails, [name]: value });
//   };

//   const projectFields = [
//     { label: "Project Name", name: "name", className: "narrow" },
//     {
//       label: "Construction Year",
//       name: "construction_year",
//       className: "narrow",
//     },
//     {
//       label: "Modernization Year",
//       name: "last_modernized",
//       className: "narrow",
//     },
//     {
//       label: "Type",
//       name: "type",
//       type: "select",
//       className: "narrow",
//       options: ["Office", "Commercial", "Public", "Residential Multi-Family"],
//     },
//     {
//       label: "Gross Asset Size",
//       name: "gross_asset_size",
//       className: "narrow",
//     },
//     { label: "Asset ID", name: "assetId", className: "narrow" },
//     { label: "Location", name: "location", className: "narrow" },
//     { label: "Address", name: "address", className: "wide" },
//     { label: "Descriptive Name", name: "descriptiveName", className: "wide" },
//     { label: "Energy Label", name: "energyLabel", className: "narrow" },
//     { label: "City", name: "city", className: "narrow" },
//     { label: "Street", name: "street", className: "narrow" },
//     { label: "Postal Code", name: "postCode", className: "narrow" },
//   ];

//   return (
//     <div>
//       <WithNavbar />
//       <TopNavbar />
//       <div className="upload-container">
//         {isLoading && <LoadingSpinner />}
//         <h2>Digital Twin Viewer Insertion</h2>

//         <form onSubmit={handleSubmit}>
//           <h1>Set Project Generic Information</h1>
//           <div className="form-grid">
//             {projectFields.map((field) =>
//               field.type === "select" ? (
//                 <ProjectDetailsInput
//                   key={field.name}
//                   label={field.label}
//                   name={field.name}
//                   value={projectDetails[field.name]}
//                   onChange={handleDetailChange}
//                   className={field.className}
//                   type={field.type}
//                   options={field.options}
//                 />
//               ) : (
//                 <ProjectDetailsInput
//                   key={field.name}
//                   label={field.label}
//                   name={field.name}
//                   value={projectDetails[field.name]}
//                   onChange={handleDetailChange}
//                   className={field.className}
//                 />
//               )
//             )}
//             <div className="file-upload">
//               <label>Upload Your Image File Here</label>
//               <div className="file-upload-wrapper">
//                 <input
//                   type="file"
//                   name="projectImage"
//                   onChange={handleImageChange}
//                 />
//                 <label className="file-upload-label">Choose File</label>
//                 <span className="file-name"></span>
//               </div>
//             </div>
//           </div>
//           <button type="submit" className="submit-button">
//             Submit
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default DigitalTwinViewerInsertion;
import React, { useState } from "react";
import "./FileUpload.css";
import ProjectDetailsInput from "./ProjectDetailsInput";
import { useNavigate } from "react-router-dom";
import TopNavbar from "./TopNavbar";
import fetchUtil from "./utils/fetchUtil";
import WithNavbar from "./LeftNavbar";
const LoadingSpinner = () => <div className="spinner">Loading...</div>;

const DigitalTwinViewerInsertion = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const apiRootOperational = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const [isLoading, setIsLoading] = useState(false);
  const [projectImage, setProjectImage] = useState(null);
  const [projectDetails, setProjectDetails] = useState({
    constructionYear: "",
    name: "",
    type: "office",
    lastModernized: "",
    grossAssetSize: "",
    descriptiveName: "",
    assetId: "",
    // location: "",
    address: "",
    energyLabel: "",
    city: "",
    street: "",
    postCode: "",
  });

  const handleImageChange = (e) => {
    setProjectImage(e.target.files[0]);
  };

  const token = localStorage.getItem("token");
  const userID = localStorage.getItem("userID");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formattedData = {
      ...projectDetails,
      constructionYear: projectDetails.construction_year
        ? parseInt(projectDetails.construction_year)
        : null,
      lastModernized: projectDetails.last_modernized
        ? parseInt(projectDetails.last_modernized)
        : null,
      grossAssetSize: projectDetails.gross_asset_size
        ? parseInt(projectDetails.gross_asset_size)
        : null,
      processing: 0,
      customerId: 1,
    };

    try {
      const projectData = await fetchUtil(
        `${apiRootOperational}/api/projects`,
        "POST",
        token,
        formattedData
      );
      const projectID = projectData.id;

      if (projectImage) {
        const formData = new FormData();
        formData.append("image_file", projectImage);
        formData.append("project", projectID);

        const imageUploadData = await fetchUtil(
          `${apiRoot}/api/images/`,
          "POST",
          token,
          formData,
          true
        );
        console.log("Image upload successful", imageUploadData);
      }

      const userProjectAssignmentBody = {
        project: projectID,
        user: userID,
      };
      const userProjectAssignmentData = await fetchUtil(
        `${apiRoot}/api/project_assignment/`,
        "POST",
        token,
        userProjectAssignmentBody
      );
      console.log("Project assignment successful", userProjectAssignmentData);

      setIsLoading(false);
      navigate("/user-projects");
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error.message);
    }
  };

  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails({ ...projectDetails, [name]: value });
  };

  const projectFields = [
    { label: "Project Name", name: "name", className: "narrow" },
    {
      label: "Construction Year",
      name: "construction_year",
      className: "narrow",
    },
    {
      label: "Modernization Year",
      name: "last_modernized",
      className: "narrow",
    },
    {
      label: "Type",
      name: "type",
      type: "select",
      className: "narrow",
      options: ["Office", "Commercial", "Public", "Residential Multi-Family"],
    },
    {
      label: "Gross Asset Size",
      name: "gross_asset_size",
      className: "narrow",
    },
    { label: "Asset ID", name: "assetId", className: "narrow" },
    // { label: "Location", name: "location", className: "narrow" },
    { label: "Address", name: "address", className: "wide" },
    { label: "Descriptive Name", name: "descriptiveName", className: "wide" },
    { label: "Energy Label", name: "energyLabel", className: "narrow" },
    { label: "City", name: "city", className: "narrow" },
    { label: "Street", name: "street", className: "narrow" },
    { label: "Postal Code", name: "postCode", className: "narrow" },
  ];

  return (
    <div>
      <WithNavbar />
      <TopNavbar />
      <div className="upload-container">
        {isLoading && <LoadingSpinner />}
        <h2>Digital Twin Viewer Insertion</h2>

        <form onSubmit={handleSubmit}>
          <h1>Set Project Generic Information</h1>
          <div className="form-grid">
            {projectFields.map((field) =>
              field.type === "select" ? (
                <ProjectDetailsInput
                  key={field.name}
                  label={field.label}
                  name={field.name}
                  value={projectDetails[field.name]}
                  onChange={handleDetailChange}
                  className={field.className}
                  type={field.type}
                  options={field.options}
                />
              ) : (
                <ProjectDetailsInput
                  key={field.name}
                  label={field.label}
                  name={field.name}
                  value={projectDetails[field.name]}
                  onChange={handleDetailChange}
                  className={field.className}
                />
              )
            )}

            <div className="info-message">
              <p>
                To calculate Latitude and Longitude, please make sure you fill
                out the Street, City, and Postal Code fields below.
              </p>
            </div>

            <ProjectDetailsInput
              key="city"
              label="City"
              name="city"
              value={projectDetails.city}
              onChange={handleDetailChange}
              className="narrow"
            />
            <ProjectDetailsInput
              key="street"
              label="Street"
              name="street"
              value={projectDetails.street}
              onChange={handleDetailChange}
              className="narrow"
            />
            <ProjectDetailsInput
              key="postCode"
              label="Postal Code"
              name="postCode"
              value={projectDetails.postCode}
              onChange={handleDetailChange}
              className="narrow"
            />

            <div className="file-upload">
              <label>Upload Your Image File Here</label>
              <div className="file-upload-wrapper">
                <input
                  type="file"
                  name="projectImage"
                  onChange={handleImageChange}
                />
                <label className="file-upload-label">Choose File</label>
                <span className="file-name"></span>
              </div>
            </div>
          </div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default DigitalTwinViewerInsertion;
