import React, { useState } from "react";
import "./RegisterUser.css";
import TopNavbar from "./TopNavbar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegisterUser = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const registerUser = async (userData) => {
    const formData = new FormData();
    formData.append("email", userData.username);
    formData.append("password", userData.password);
    formData.append("confirmPassword", userData.confirmPassword);

    try {
      const response = await fetch(`${apiRoot}/api/user`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        console.error(`Error: ${response.status} - ${response.statusText}`);

        const errorData = await response.json();

        console.error("Error Data:", errorData);

        throw new Error(errorData.error || "Failed to register");
      }

      return await response.json();
    } catch (error) {
      console.error("Caught Error:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    try {
      const data = await registerUser({ username, password, confirmPassword });
      console.log("Registration successful", data);

      toast.success("User Created successfully!");
      setUsername("");
      setPassword("");
      setConfirmPassword("");
      setMessage("");
    } catch (error) {
      console.log(username);
      console.log(password);
      console.log(confirmPassword);
      toast.error("Registration failed: " + error.message);
      setMessage(error.message || "Failed to register");
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <TopNavbar />
      <div className="register-container">
        {message && (
          <div
            className={`message ${
              password !== confirmPassword ? "error-message" : "success-message"
            }`}
          >
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit} className="register-form">
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <button type="submit">Register</button>
        </form>
      </div>
    </>
  );
};

export default RegisterUser;
