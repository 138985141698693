import React, { useState } from "react";
import "./RegisterUser.css";
import "./Features.css";
import { useNavigate } from "react-router-dom";
import TopNavbar from "./TopNavbar";

// console.log(projectID);
const Feature = ({ title, description, onClick }) => {
  return (
    <div className="feature" onClick={onClick}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};
const Features = () => {
  const projectID = localStorage.getItem("projectID");
  //   const { projectId } = useParams();
  const features = [
    {
      title: "Project Generic Information Setup",
      description:
        "Here, you can create a project for platform or digital twin viewer",
      // path: "project/:projectId",
      path: `project/${projectID}`,
      requiresProjectId: true,
    },
    {
      title: "Scene Setup",
      description: "Insert your GLB Files here",
      path: `scene-setup/${projectID}`,
    },
    {
      title: "Window Setup",
      description: "Insert your GLB Files here",
      path: `window-setup/${projectID}`,
    },
    // {
    //   title: "Room Footprint Setup",
    //   description: "Insert your Data Files here",
    //   path: `room-footprint/${projectID}`,
    // },

    {
      title: "Virtual Solar Sensor Setup",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",
      //   path: "virtual-sensor-setup/:projectId",
      path: `virtual-sensor-setup/${projectID}`,
    },
    {
      title: "Virtual Wind Setup",
      description:
        "Here, you can create a project for platform or digital twin viewer",
      path: `virtual-wind/${projectID}`,
    },
    {
      title: "PV Data Setup",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",

      path: `pv-panel-setup/${projectID}`,
    },
    // {
    //   title: "Thermal Heatmap Setup",
    //   description:
    //     "Here, you can edit an exiting project for platform or digital twin viewer",
    //   path: `energy-data/${projectID}`,
    // },
    {
      title: "New room Heatmap Setup",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",
      path: `room-new/${projectID}`,
    },

    {
      title: "File Setup",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",
      path: `pure-file-setup/${projectID}`,
    },
    {
      title: "Technical Data Setup",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",
      path: `technical-data/${projectID}`,
    },
    {
      title: "Crrem Data Setup",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",
      path: `crrem-data-setup/${projectID}`,
    },
    {
      title: "Wind Risk Data Setup",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",
      path: `wind-risk/${projectID}`,
    },
    {
      title: "Shading Progression Data Setup",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",
      path: "user-projects",
    },
    {
      title: "Energy dashboard Data",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",
      path: `energy-dashboard-setup/${projectID}`,
    },
  ];

  const navigate = useNavigate();

  const handleFeatureClick = (feature) => {
    const projectID = localStorage.getItem("projectID");
    if (feature.requiresProjectId) {
      if (!projectID) {
        console.error("No projectId available for navigation.");
        return;
      }
      const pathWithProjectId = feature.replace(":projectId", projectID);
      navigate(pathWithProjectId);
    } else {
      navigate(feature);
    }
  };

  return (
    <div className="container" id="features">
      <h2>Platform Features</h2>
      <div className="features">
        {features.map((feature, index) => (
          <Feature
            key={index}
            title={feature.title}
            description={feature.description}
            onClick={() => handleFeatureClick(`/${feature.path}`)}
          />
        ))}
      </div>
    </div>
  );
};

const ProjectEdit = () => {
  // window.location.reload();
  const projectID = localStorage.getItem("projectID");
  const user = null;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  if (!token) {
    console.log("nadarad");
    return <p>Please login to view this page.</p>;
  }

  return (
    <div>
      <TopNavbar />
      <Features />
      {/* <ActivityReport /> */}
    </div>
  );
};

export default ProjectEdit;
