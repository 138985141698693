// utils/fetchUtil.js
// const fetchUtil = async (
//   url,
//   method,
//   token,
//   body = null,
//   isFormData = false
// ) => {
//   const headers = {
//     Authorization: `Bearer ${token}`,
//   };

//   if (!isFormData) {
//     headers["Content-Type"] = "application/json";
//   }

//   const response = await fetch(url, {
//     method: method,
//     headers: headers,
//     body: isFormData ? body : JSON.stringify(body),
//   });

//   if (!response.ok) {
//     const errorData = await response.json();
//     throw new Error(errorData.message || "Error with API call");
//   }

//   return response.json();
// };

// export default fetchUtil;
// utils/fetchUtil.js

// this is correct one
// const fetchUtil = async (
//   url,
//   method,
//   token,
//   body = null,
//   isFormData = false
// ) => {
//   const headers = {
//     Authorization: `Bearer ${token}`,
//   };

//   if (!isFormData && method !== "GET") {
//     headers["Content-Type"] = "application/json";
//   }

//   const fetchOptions = {
//     method: method,
//     headers: headers,
//   };

//   if (body && method !== "GET") {
//     fetchOptions.body = isFormData ? body : JSON.stringify(body);
//   }

//   const response = await fetch(url, fetchOptions);

//   if (!response.ok) {
//     const errorData = await response.json();
//     throw new Error(errorData.message || "Error with API call");
//   }

//   return response.json();
// };
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const fetchUtil = async (
  url,
  method,
  token,
  body = null,
  isFormData = false
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  if (!isFormData && method !== "GET") {
    headers["Content-Type"] = "application/json";
  }

  const fetchOptions = {
    method: method,
    headers: headers,
  };

  if (body && method !== "GET") {
    fetchOptions.body = isFormData ? body : JSON.stringify(body);
  }

  const response = await fetch(url, fetchOptions);

  if (!response.ok) {
    try {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error with API call");
    } catch (jsonError) {
      // This error occurs if the response is not JSON or is empty, handle or throw as appropriate
      throw new Error("Failed to parse error response as JSON.");
    }
  }

  // Check if the response has content before attempting to parse it as JSON
  const text = await response.text(); // first read the stream to completion
  return text ? JSON.parse(text) : {};
};

export default fetchUtil;
