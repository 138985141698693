import React, { useState, useRef } from "react";
import "./FileUpload.css";
import ProjectDetailsInput from "./ProjectDetailsInput";
import FileUploadWithoutFeatureSingleComponent from "./FileUploadWithoutFeatureComponents";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import WithNavbar from "./LeftNavbar";
import TopNavbar from "./TopNavbar";
import * as XLSX from "xlsx";
import JSZip from "jszip";
const FileSingleComponent = ({
  heading1,
  heading2,
  fileUploadComponents,
  showEdit,
  editText,
  showEditHover,
  apiContextArg,
  apiText,
}) => {
  const [columns, setColumns] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const fileInputRefs = useRef(
    fileUploadComponents.map(() => React.createRef())
  );
  // const [uploadProgress, setUploadProgress] = useState({});
  const [windowCount, setWindowCount] = useState(0);
  const processFile = async (file) => {
    const zip = new JSZip();
    const zipContents = await zip.loadAsync(file);
    const excelFileName = Object.keys(zipContents.files).find((filename) =>
      /\.xlsx$/.test(filename)
    );

    if (!excelFileName) {
      throw new Error("No Excel file found in the ZIP.");
    }

    const excelFile = await zipContents.files[excelFileName].async("blob");
    return readExcelColumns(excelFile);
  };

  const readExcelColumns = (excelFile) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
        resolve(headers); // Resolves with the column headers
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(excelFile);
    });
  };
  console.log("ooops");
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  const navigate = useNavigate();
  const [fileData, setFileData] = useState({});
  const handleFileSelect = async (id, file) => {
    setFileData((prevData) => ({ ...prevData, [id]: file }));

    try {
      const columnHeaders = await processFile(file);
      setColumns(columnHeaders);
      console.log(columnHeaders);
    } catch (error) {
      console.error("Error in file processing:", error);
      alert("Failed to process file: " + error.message);
    }
  };

  const [mappings, setMappings] = useState({
    cooling_system_value_simulated: "",
    heating_system_value_simulated: "",
    cooling_system_value_measured: "",
    heating_system_value_measured: "",
  });
  const handleMappingChange = (field) => (event) => {
    setMappings({ ...mappings, [field]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let allUploadsSuccessful = true;
    for (const component of fileUploadComponents) {
      var url = component.uploadUrl;
      const file = fileData[component.id];
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          //   for (let [key, value] of formData.entries()) {
          //     console.log(key, value);
          //   }
          Object.entries(mappings).forEach(([key, value]) => {
            if (value) {
              url.searchParams.append(key, value);
            }
          });

          console.log(formData);
          if (isEditMode) {
            const queryParams = new URLSearchParams({
              edit_direction: "yes",
            }).toString();
            url += "&" + queryParams;
          }
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              // "Content-Type": "application/json",
            },
            body: formData,
          });

          const data = await response.json();
          console.log(data);
          if (data.status !== "processing") {
            allUploadsSuccessful = false;
            throw new Error("Error uploading file");
          }
        } catch (error) {
          console.error(`Error uploading file for ${component.id}:`, error);
          window.alert(`Error uploading file: ${error.message}`);
          allUploadsSuccessful = false;
        }
      }
    }
    if (allUploadsSuccessful) {
      window.alert("File(s) uploaded successfully");
      setFileData({});
      fileInputRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.value = "";
        }
      });
    }
  };

  return (
    <div>
      <WithNavbar />
      <TopNavbar />
      {/* <div className="navbar">
        <h1>Digital Twin Platform</h1>
        <button onClick={() => window.history.back()}>Back to Features</button>
      </div>
      <Sidebar /> */}
      <div className="upload-container">
        <h2>{heading2}</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <h1>{heading1}</h1>
          </div>
          <div className="file-upload-class">
            {fileUploadComponents.map((component, index) => (
              <FileUploadWithoutFeatureSingleComponent
                key={index}
                label={component.label}
                id={component.id}
                onFileSelect={handleFileSelect}
                ref={fileInputRefs.current[index]}
              />
            ))}
            <div>
              <div>
                {apiContextArg
                  ? `${apiText}: ${apiContextArg}`
                  : "No context data"}
              </div>
              {/* Other component content */}
            </div>
            <div className="mapping-container">
              <h3>
                You can select the column mapping with the file name after file
                selection
              </h3>
              {columns.length > 0 && (
                <div>
                  {Object.keys(mappings).map((key) => (
                    <div key={key} className="mapping-item">
                      <label className="mapping-label">
                        {key.replace("_", " ").toUpperCase()}:
                      </label>
                      <select
                        className="mapping-select"
                        value={mappings[key]}
                        onChange={(e) =>
                          handleMappingChange(key, e.target.value)
                        }
                      >
                        <option value="">Select a column...</option>
                        {columns.map((header, index) => (
                          <option key={index} value={header}>
                            {header}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {showEdit ? (
            <div>
              <input
                type="checkbox"
                id="editSceneOrder"
                checked={isEditMode}
                onChange={(e) => setIsEditMode(e.target.checked)}
                title={showEditHover}
              />
              <label htmlFor="editSceneOrder">{editText}</label>
            </div>
          ) : (
            ""
          )}

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default FileSingleComponent;
