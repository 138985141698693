import React from "react";
import FileSingleComponent from "./FileSingleComponent";
import { useState } from "react";

const ThermalSe = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  return (
    <div>
      <FileSingleComponent
        heading1="Upload Your Project Virtual Wind and Virtual Sensor"
        heading2="Digital Twin File Uploader"
        fileUploadComponents={[
          {
            label: "Upload Your virtual wind data here",
            id: "wind-setup",
            uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=thermal`,
          },
        ]}
        showEdit="true"
      />
    </div>
  );
};

export default ThermalSe;
