import React, { useState } from "react";
import "./TechnicalData.css";
import fetchUtil from "./utils/fetchUtil";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopNavbar from "./TopNavbar";
import WithNavbar from "./LeftNavbar";
const token = localStorage.getItem("token");
const transformData = (apiData) => {
  const transformedData = {
    windows: [],
    rooftops: [],
    walls: [],
  };

  Object.keys(apiData).forEach((key) => {
    apiData[key].forEach((component) => {
      const transformedComponent = {
        id: component.id,
        type: component.type,
        data: {
          description: component.description,
          area: component.area,
        },
        layers: component.layers.map((layer) => ({
          name: layer.name,
          id: layer.id,
          thickness: layer.thickness,
          // thickness: layer.thickness,
          density: layer.density,
          thermal_conductivity: layer.thermal_conductivity,
          heat_capacity: layer.heat_capacity,
        })),
      };
      transformedData[component.type].push(transformedComponent);
    });
  });

  return transformedData;
};

const apiRoot = process.env.REACT_APP_API_ROOT;
const componentTypes = {
  windows: "Windows",
  rooftops: "Rooftops",
  walls: "Walls",
};

const layerFieldsWhenEdit = {
  windows: ["heating", "cooling", "hggg"],
  rooftops: [
    "name",
    "thickness",
    "density",
    "thermal_conductivity",
    "heat_capacity",
  ],
  walls: [
    // "id",
    "name",
    "thickness",
    "density",
    "thermal_conductivity",
    "heat_capacity",
  ],
};
const layerFieldsss = {
  windows: ["heating", "cooling", "hggg"],
  rooftops: [
    "id",
    "thickness",
    "name",
    "density",
    "thermal_conductivity",
    "heat_capacity",
  ],
  walls: [
    // "id",
    "thickness",
    "name",
    "density",
    "thermal_conductivity",
    "heat_capacity",
  ],
};

const componentFields = {
  windows: [
    "window_type",
    "number_of_windows",
    "area",
    "u_value",
    "g_value",
    // "project",
  ],
  rooftops: [
    "description",
    "area",
    "thickness",
    "u_value",
    "color",
    "modernization",
    // "project",
  ],
  walls: [
    "description",
    "area",
    "thickness",
    "u_value",
    "color",
    "modernization",
    // "project",
  ],
};

const POST_LAYER_API_URLS = {
  windows: `${apiRoot}/api/windows_layer_interaction/`,
  rooftops: `${apiRoot}/api/rooftop_layer_interaction/`,
  walls: `${apiRoot}/api/facade_layer_interaction/`,
};
const TechnicalData = () => {
  const projectID = localStorage.getItem("projectID");
  const consoleWarn = console.warn;
  console.warn = function (...args) {
    if (/Encountered two children with the same key/.test(args[0])) {
      return;
    }
    consoleWarn.apply(console, args);
  };

  const projectId = localStorage.getItem("projectID");
  const [layerEdits, setLayerEdits] = useState({});
  const [editingLayer, setEditingLayer] = useState({
    componentId: null,
    layerIndex: null,
  });
  const fetchProjects = async () => {
    // const token = localStorage.getItem("token"); // Get the token from storage
    try {
      const response = await fetch(`${apiRoot}/api/projects/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch projects");
      }
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const apiRoot = process.env.REACT_APP_API_ROOT;

  const [projects, setProjects] = useState([]);
  // const [selectedProjectId, setSelectedProjectId] = useState(null);
  const API_URLS = {
    windows: `${apiRoot}/api/layers/get_windows_list_with_layers_of_project/?project_id=${projectID}`,
    rooftops: `${apiRoot}/api/layers/get_rooftop_list_with_layers_of_project/?project_id=${projectID}`,
    walls: `${apiRoot}/api/layers/get_facade_list_with_layers_of_project/?project_id=${projectID}`, // Assuming 'walls' is your API endpoint for facade
  };
  const POST_API_URLS = {
    windows: `${apiRoot}/api/windows_interaction/`,
    rooftops: `${apiRoot}/api/rooftop_interaction/`,
    walls: `${apiRoot}/api/facade_interaction/`,
  };
  const [showLayerCreation, setShowLayerCreation] = useState(false);
  const handleToggleLayerCreation = () => {
    setShowLayerCreation(!showLayerCreation);
  };

  const [openCollapsible, setOpenCollapsible] = useState(new Set());

  const toggleCollapsible = (componentId, type) => {
    const uniqueId = `${type}-${componentId}`;
    setOpenCollapsible((prevOpen) => {
      const newOpen = new Set(prevOpen);
      if (newOpen.has(uniqueId)) {
        newOpen.delete(uniqueId);
      } else {
        newOpen.add(uniqueId);
      }
      return newOpen;
    });
  };

  const [editingComponentId, setEditingComponentId] = useState(null);

  const startEditComponent = (componentId) => {
    setEditingComponentId(componentId);
  };

  const [newComponentType, setNewComponentType] = useState("windows");
  const [newComponentData, setNewComponentData] = useState({});

  const [selectedComponent, setSelectedComponent] = useState(null);
  const [newLayerData, setNewLayerData] = useState({});

  const handleEditComponent = (componentId, field, value) => {
    setComponents((prevComponents) => {
      const updatedComponents = {};
      for (const key in prevComponents) {
        updatedComponents[key] = prevComponents[key].map((comp) =>
          comp.id === componentId
            ? { ...comp, data: { ...comp.data, [field]: value } }
            : comp
        );
      }
      console.log(updatedComponents);

      return updatedComponents;
    });
  };

  const handleAddComponentClick = async () => {
    const newComponent = {
      type: newComponentType,
      data: newComponentData,
      layers: [],
    };

    setComponents((prevComponents) => ({
      ...prevComponents,
      [newComponentType]: [...prevComponents[newComponentType], newComponent],
    }));

    setNewComponentData({});
    setSelectedComponent(null);
    const postData = {
      ...newComponentData,
      project: projectId,
    };
    try {
      const response = await fetch(POST_API_URLS[newComponentType], {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(
          responseData.message || "Failed to create the component"
        );
      }
      toast.success("Component added successfully!");
      console.log("Component added successfully:", responseData);
    } catch (error) {
      toast.error("Error adding component: " + error.message);
      console.error("Error adding component:", error);
    }
  };

  const [components, setComponents] = useState({
    windows: [],
    rooftops: [],
    walls: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const windowsData = await fetchUtil(
          API_URLS.windows,
          "GET",
          `${token}`
        );
        const rooftopsData = await fetchUtil(
          API_URLS.rooftops,
          "GET",
          `${token}`
        );
        const wallsData = await fetchUtil(API_URLS.walls, "GET", `${token}`);
        const windowsWithTypes = windowsData.map((item) => ({
          ...item,
          type: "windows",
        }));
        const rooftopsWithTypes = rooftopsData.map((item) => ({
          ...item,
          type: "rooftops",
        }));
        const wallsWithTypes = wallsData.map((item) => ({
          ...item,
          type: "walls",
        }));
        const transformedData = transformData({
          windows: windowsWithTypes,
          rooftops: rooftopsWithTypes,
          walls: wallsWithTypes,
        });

        setComponents(transformedData);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const renderInputsForComponentType = (component) => {
    const fields = componentFields[component.type];
    return fields.map((field) => (
      <input
        key={`${component.id}-${field}`}
        type="text"
        placeholder={field}
        value={component.data[field] || ""}
        onChange={(e) =>
          handleEditComponent(component.id, field, e.target.value)
        }
        // onChange={(e) => handleInputChange(field, e.target.value, layer.id)}
      />
    ));
  };

  const API_BASE = process.env.REACT_APP_API_ROOT;

  const deleteLayer = async (url) => {
    console.log(url);
    try {
      const response = await fetchUtil(url, "DELETE", `${token}`);
      console.log(response);
    } catch (error) {
      console.error("Error deleting layer:", error);
    }
  };

  const allowedFields = [
    "name",
    "thickness",
    "density",
    "thermal_conductivity",
    "heat_capacity",
  ];

  const filterLayerFields = (layer) => {
    const filteredLayer = {};
    allowedFields.forEach((field) => {
      if (layer.hasOwnProperty(field)) {
        filteredLayer[field] = layer[field];
      }
    });
    return filteredLayer;
  };
  const saveLayerEdits = async (layerId, type, componentId) => {
    if (!layerEdits[layerId]) {
      console.error("No edits to save for layer:", layerId);
      return;
    }

    const url = `${POST_LAYER_API_URLS[type]}${layerId}/`;
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(layerEdits[layerId]),
      });

      const updatedLayer = await response.json();
      console.log(updatedLayer);
      if (!response.ok) throw new Error("Failed to update layer");

      toast.success("Layer updated successfully!");
      // } catch (error) {
      //   toast.error("Error deleting component: " + error.message);
      setComponents((prevComponents) => {
        const updatedFilteredLayer = filterLayerFields(updatedLayer);
        return {
          ...prevComponents,
          [type]: prevComponents[type].map((comp) => {
            if (comp.id === componentId) {
              let updatedLayers = comp.layers.map((layer) => {
                if (layer.id === updatedLayer.id) {
                  return { ...layer, ...updatedFilteredLayer };
                }
                return layer;
              });
              const layerIds = new Set(comp.layers.map((layer) => layer.id));
              if (!layerIds.has(updatedLayer.id)) {
                console.log(updatedFilteredLayer);
                updatedLayers = [...updatedLayers, updatedFilteredLayer];
              }
              return { ...comp, layers: updatedLayers };
            }
            return comp;
          }),
        };
      });
    } catch (error) {
      console.error("Error updating layer:", error.message);
    }
  };

  const handleInputChange = (field, value, layerId) => {
    setLayerEdits((prev) => ({
      ...prev,
      [layerId]: {
        ...prev[layerId],
        [field]: value,
      },
    }));
  };

  const handleDeleteLayerhh = (componentId, layerIndex, componentType) => {
    let url = POST_LAYER_API_URLS[componentType];

    url = `${url}${layerIndex}/`;

    deleteLayer(url);
  };

  const handleDeleteLayer = async (componentId, layerId, type) => {
    console.log(layerId);
    if (!layerEdits[layerId]) {
      console.error("No edits to save for layer:", layerId);
      return;
    }

    const url = `${POST_LAYER_API_URLS[type]}${layerId}/`;
    console.log(url);
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(layerEdits[layerId]),
      });

      const updatedLayer = await response.json();
      console.log(updatedLayer);
      if (!response.ok) throw new Error("Failed to update layer");

      toast.success("Layer Deleted successfully!");
      // } catch (error) {
      //   toast.error("Error deleting component: " + error.message);
      setComponents((prevComponents) => {
        const updatedFilteredLayer = filterLayerFields(updatedLayer);
        return {
          ...prevComponents,
          [type]: prevComponents[type].map((comp) => {
            if (comp.id === componentId) {
              let updatedLayers = comp.layers.map((layer) => {
                if (layer.id === updatedLayer.id) {
                  return { ...layer, ...updatedFilteredLayer };
                }
                return layer;
              });
              const layerIds = new Set(comp.layers.map((layer) => layer.id));
              if (!layerIds.has(updatedLayer.id)) {
                console.log(updatedFilteredLayer);
                updatedLayers = [...updatedLayers, updatedFilteredLayer];
              }
              return { ...comp, layers: updatedLayers };
            }
            return comp;
          }),
        };
      });
    } catch (error) {
      console.error("Error updating layer:", error.message);
    }
  };

  const handleConfirmNewLayer = async (componentId, componentType) => {
    console.log("fajlajlfjlajfljaljflajlfjal");
    console.log(componentType);
    const component = components[componentType].find(
      (comp) => comp.id === componentId
    );
    console.log(component);
    if (!component) {
      console.error("Component not found!");
      return;
    }

    const url = POST_LAYER_API_URLS[componentType];
    console.log(url);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          ...newLayerData,
          rooftop: componentId,
          facade: componentId,
          window: componentId,
        }),
      });

      const responseData = await response.json();
      const updatedFilteredLayer = filterLayerFields(responseData);
      if (!response.ok)
        throw new Error(responseData.message || "Failed to create the layer");
      toast.success("Layer created successfully!");
      setComponents((prevComponents) => ({
        ...prevComponents,
        [componentType]: prevComponents[componentType].map((comp) => {
          if (comp.id === componentId) {
            return { ...comp, layers: [...comp.layers, updatedFilteredLayer] };
          }
          return comp;
        }),
      }));

      console.log("Layer added successfully:", responseData);
      setShowLayerCreation(null);
      setNewLayerData({});
    } catch (error) {
      console.error("Error adding layer:", error.message);
    }
  };

  const handleCancelNewLayer = () => {
    setShowLayerCreation(null);
    setNewLayerData({});
  };
  const updateComponentEdit = async (componentId, componentType) => {
    const url = POST_API_URLS[componentType] + componentId + "/";
    const component = components[componentType].find(
      (comp) => comp.id === componentId
    );
    if (!component) {
      toast.error("Component not found!");
      return;
    }

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(component.data),
        // body: JSON.stringify(componentData.data),
      });
      const responseData = await response.json();
      if (!response.ok)
        throw new Error(
          responseData.message || "Failed to update the component"
        );

      setComponents((prevComponents) => ({
        ...prevComponents,
        [componentType]: prevComponents[componentType].map((comp) =>
          comp.id === componentId ? { ...comp, ...responseData } : comp
        ),
      }));

      toast.success("Component updated successfully!");
    } catch (error) {
      toast.error("Error updating component: " + error.message);
      console.error("Error updating component:", error);
    }
  };
  const handleDeleteComponent = async (componentId, componentType) => {
    const url = POST_API_URLS[componentType];

    try {
      const response = await fetch(`${url}${componentId}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Failed to delete the component");

      setComponents((prevComponents) => ({
        ...prevComponents,
        [componentType]: prevComponents[componentType].filter(
          (comp) => comp.id !== componentId
        ),
      }));

      toast.success("Component deleted successfully!");
    } catch (error) {
      toast.error("Error deleting component: " + error.message);
      console.error("Error deleting component:", error);
    }
  };

  return (
    <div>
      <WithNavbar />
      <TopNavbar />
      <div className="technical-data">
        <div className="component-creation">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
          />
          <select
            className="select-button"
            value={newComponentType}
            onChange={(e) => setNewComponentType(e.target.value)}
          >
            {Object.keys(componentTypes).map((type) => (
              <option key={type} value={type}>
                {componentTypes[type]}
              </option>
            ))}
          </select>
          {componentFields[newComponentType].map((field) => (
            <input
              key={field}
              type="text"
              placeholder={field}
              value={newComponentData[field] || ""}
              onChange={(e) =>
                setNewComponentData({
                  ...newComponentData,
                  [field]: e.target.value,
                })
              }
            />
          ))}
          <button onClick={handleAddComponentClick} className="add-button">
            Add Component
          </button>
        </div>
        <div className="component-list">
          {Object.keys(components).map((type) => (
            <div key={type} className={`${type}-border grouped-section`}>
              <h2>{componentTypes[type]}</h2>
              {components[type].map((component) => (
                <div key={component.id} className="component-info">
                  <div style={{ display: "flex" }}>
                    <button
                      className={`expand-button ${
                        openCollapsible.has(`${type}-${component.id}`)
                          ? "open"
                          : ""
                      }`}
                      onClick={() => toggleCollapsible(component.id, type)}
                    >
                      {openCollapsible.has(`${type}-${component.id}`)
                        ? "-"
                        : "+"}
                    </button>
                    <div className="editable-component-summary">
                      <div className="input-and-actions-container">
                        {renderInputsForComponentType(component)}
                      </div>
                    </div>
                    <div className="layer-action-buttons">
                      <button
                        className="icon-button"
                        // onClick={() => updateComponentEdit(layer.id)}
                        onClick={() => updateComponentEdit(component.id, type)}
                      >
                        <i className="material-icons blue-icon">edit</i>
                      </button>

                      <button
                        className="icon-button"
                        onClick={() =>
                          handleDeleteComponent(component.id, component.type)
                        }
                      >
                        <i className="material-icons blue-icon">delete</i>
                      </button>
                    </div>
                  </div>

                  <div
                    className={`collapsible-content ${
                      openCollapsible.has(`${type}-${component.id}`)
                        ? "open"
                        : ""
                    }`}
                  >
                    {component.layers.map((layer, layerIndex) => (
                      <div key={layer.id} className="layer-item">
                        <div className="layer-inputs-and-actions">
                          <div className="input-group">
                            {Object.keys(layer).map((field) => (
                              <input
                                key={layer.id}
                                type="text"
                                value={
                                  layerEdits[layer.id] &&
                                  layerEdits[layer.id][field] !== undefined
                                    ? layerEdits[layer.id][field]
                                    : layer[field]
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    field,
                                    e.target.value,
                                    layer.id
                                  )
                                }
                              />
                            ))}
                          </div>
                          <div className="layer-action-buttons">
                            <button
                              className="icon-button"
                              onClick={() =>
                                saveLayerEdits(
                                  layer.id,
                                  component.type,
                                  component.id
                                )
                              }
                            >
                              <i className="material-icons blue-icon">edit</i>
                            </button>

                            <button
                              className="icon-button"
                              onClick={() =>
                                handleDeleteLayer(
                                  component.id,
                                  layer.id,
                                  component.type
                                )
                              }
                            >
                              <i className="material-icons blue-icon">delete</i>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}

                    {showLayerCreation === component.id && (
                      <div className="new-layer-inputs">
                        {layerFieldsWhenEdit[component.type].map((field) => (
                          <input
                            key={field}
                            type="text"
                            placeholder={`New Layer ${field}`}
                            value={newLayerData[field] || ""}
                            onChange={(e) =>
                              setNewLayerData({
                                ...newLayerData,
                                [field]: e.target.value,
                              })
                            }
                          />
                        ))}

                        <div className="button-group">
                          <button
                            onClick={() =>
                              handleConfirmNewLayer(
                                component.id,
                                component.type
                              )
                            }
                          >
                            Confirm
                          </button>
                          <button onClick={() => setShowLayerCreation(null)}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="layer-button-container">
                      <button
                        className="layer-button"
                        onClick={() => setShowLayerCreation(component.id)}
                      >
                        {showLayerCreation === component.id ? "-" : "New Layer"}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechnicalData;
