import "./FileUpload.css";
import { useNavigate } from "react-router-dom";
import React, { useState, useRef } from "react";
import "./FileUpload.css";
import WithNavbar from "./LeftNavbar";
import TopNavbar from "./TopNavbar";
import FileUploadWithoutFeatureSingleComponent from "./FileUploadWithoutFeatureComponents";
import * as XLSX from "xlsx";

const FileSingleComponent = ({
  heading1,
  heading2,
  fileUploadComponents,
  showEdit,
  editText,
  showEditHover,
}) => {
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  const navigate = useNavigate();
  const [fileData, setFileData] = useState({});
  const [fileHeaders, setFileHeaders] = useState([]);
  const [mappings, setMappings] = useState({
    current_energy_consumption: "",
    crrem_energy_consumption: "",
    current_carbon_emission: "",
    crrem_carbon_emission: "",
    climate_changes_consumption: "",
    climate_changes_emission: "",
  });
  const fileInputRefs = useRef(
    fileUploadComponents.map(() => React.createRef())
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const handleFileSelect = (id, file) => {
    console.log(file);
    if (file && file instanceof Blob) {
      setFileData((prevData) => ({ ...prevData, [id]: file }));
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
        setFileHeaders(headers);
      };
      reader.readAsArrayBuffer(file);
    } else {
      console.error("The file is not valid or not received correctly.");
    }
  };

  const handleMappingChange = (field) => (event) => {
    setMappings({ ...mappings, [field]: event.target.value });
  };

  const handleSubmit = async (event) => {
    // showEdit = isEditMode;

    event.preventDefault();
    let allUploadsSuccessful = true;
    for (const component of fileUploadComponents) {
      //   var url = component.uploadUrl;
      var url = new URL(component.uploadUrl);
      const file = fileData[component.id];
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          Object.entries(mappings).forEach(([key, value]) => {
            if (value) {
              url.searchParams.append(key, value);
            }
          });

          if (isEditMode) {
            const queryParams = new URLSearchParams({
              edit_direction: "yes",
            }).toString();
            url += "&" + queryParams;
          }
          console.log(url);
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });

          const data = await response.json();
          console.log(data);
          if (data.status !== "processing") {
            allUploadsSuccessful = false;
            throw new Error("Error uploading file");
          }
        } catch (error) {
          console.error(`Error uploading file for ${component.id}:`, error);
          window.alert(`Error uploading file: ${error.message}`);
          allUploadsSuccessful = false;
        }
      }
    }
    if (allUploadsSuccessful) {
      window.alert("File(s) uploaded successfully");
      setFileData({});
      fileInputRefs.current.forEach((ref) => {
        if (ref.current) {
          ref.current.value = "";
        }
      });
    }
  };

  return (
    <div>
      <WithNavbar />
      <TopNavbar />
      <div className="upload-container">
        <h2>{heading2}</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <h1>{heading1}</h1>
          </div>
          <div className="file-upload-class">
            {fileUploadComponents.map((component, index) => (
              <FileUploadWithoutFeatureSingleComponent
                key={index}
                label={component.label}
                id={component.id}
                onFileSelect={handleFileSelect}
                // onFileSelect={(file) => handleFileSelect(component.id, file)}
                ref={fileInputRefs.current[index]}
              />
            ))}
          </div>
          {/* {fileHeaders.length > 0 && (
            <div>
              {Object.keys(mappings).map((key) => (
                <div key={key}>
                  <label>{key.replace("_", " ").toUpperCase()}:</label>
                  <select
                    value={mappings[key]}
                    onChange={handleMappingChange(key)}
                  >
                    <option value="">Select a column...</option>
                    {fileHeaders.map((header, index) => (
                      <option key={index} value={header}>
                        {header}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
          )} */}
          <div className="mapping-container">
            <h3>
              You can select the column mapping with the file name after file
              selection
            </h3>
            {fileHeaders.length > 0 && (
              <div>
                {Object.keys(mappings).map((key) => (
                  <div key={key} className="mapping-item">
                    <label className="mapping-label">
                      {key.replace("_", " ").toUpperCase()}:
                    </label>
                    <select
                      className="mapping-select"
                      value={mappings[key]}
                      onChange={(e) => handleMappingChange(key, e.target.value)}
                    >
                      <option value="">Select a column...</option>
                      {fileHeaders.map((header, index) => (
                        <option key={index} value={header}>
                          {header}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            )}
          </div>
          {showEdit && (
            <div>
              <input
                type="checkbox"
                id="editSceneOrder"
                checked={isEditMode}
                onChange={(e) => setIsEditMode(e.target.checked)}
                title={showEditHover}
              />
              <label htmlFor="editSceneOrder">{editText}</label>
            </div>
          )}
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default FileSingleComponent;
