// import React, { useState, useRef, useEffect } from "react";
// import "./FileUpload.css";
// import { useNavigate } from "react-router-dom";
// import WithNavbar from "./LeftNavbar";
// import TopNavbar from "./TopNavbar";
// import FileSingleComponent from "./FileSingleComponent";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const WindowUploadFileComponent = ({
//   heading1,
//   heading2,
//   fileUploadComponents,
//   showEdit,
//   editText,
//   showEditHover,
//   apiContextArg,
//   apiText,
//   noWindows,
// }) => {
//   const [isEditMode, setIsEditMode] = useState(false);
//   const fileInputRefs = useRef(
//     fileUploadComponents.map(() => React.createRef())
//   );
//   const [windowCount, setWindowCount] = useState(0);
//   const [windowsData, setWindowsData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [selectedProject, setSelectedProject] = useState("");
//   const [projects, setProjects] = useState([]);
//   const [activeSection, setActiveSection] = useState("");
//   const [apiResponse, setApiResponse] = useState(null);
//   const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
//   const userId = localStorage.getItem("userID");
//   const token = localStorage.getItem("token");
//   const projectID = localStorage.getItem("projectID");
//   const apiRootSecond = process.env.REACT_APP_API_ROOT;
//   const navigate = useNavigate();
//   const [fileData, setFileData] = useState({});

//   const handleFileSelect = (id, file) => {
//     setFileData((prevData) => ({ ...prevData, [id]: file }));
//   };

//   const handleCopyWindows = async () => {
//     console.log("inja");

//     toast.info("Copying windows, please wait...");

//     if (!selectedProject) {
//       toast.error("Please select a project first.");
//       return;
//     }
//     console.log(selectedProject);
//     console.log(
//       `${apiRoot}/api/rooms/copy?oldProjectId=${selectedProject}&newProjectId=${projectID}`
//     );

//     try {
//       const response = await fetch(
//         `${apiRoot}/api/rooms/copy?oldProjectId=${selectedProject}&newProjectId=${projectID}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//           body: JSON.stringify({
//             sourceProjectId: selectedProject,
//             targetProjectId: projectID,
//           }),
//         }
//       );

//       const data = await response.json();
//       setApiResponse(data);
//       console.log("kkkkkkkkk");

//       if (response.ok) {
//         console.log("jflajlfjlajl");
//         toast.success("Windows copied successfully!");
//       } else {
//         toast.error(`Error: ${data.message || "Failed to copy windows."}`);
//       }

//       console.log(
//         `Windows copied successfully from project ID: ${selectedProject}`
//       );
//     } catch (error) {
//       console.error("Error copying windows:", error);
//       toast.error("Failed to copy windows. Please try again.");
//     }
//   };
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     let allUploadsSuccessful = true;
//     for (const component of fileUploadComponents) {
//       var url = component.uploadUrl;
//       const file = fileData[component.id];
//       if (file) {
//         try {
//           const formData = new FormData();
//           formData.append("file", file);

//           if (isEditMode) {
//             const queryParams = new URLSearchParams({
//               edit_direction: "yes",
//             }).toString();
//             url += "&" + queryParams;
//           }
//           const response = await fetch(url, {
//             method: "POST",
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//             body: formData,
//           });

//           const data = await response.json();
//           if (data.status !== "processing") {
//             allUploadsSuccessful = false;
//             throw new Error("Error uploading file");
//           }
//         } catch (error) {
//           console.error(`Error uploading file for ${component.id}:`, error);
//           window.alert(`Error uploading file: ${error.message}`);
//           allUploadsSuccessful = false;
//         }
//       }
//     }
//     if (allUploadsSuccessful) {
//       window.alert("File(s) uploaded successfully");
//       setFileData({});
//       fileInputRefs.current.forEach((ref) => {
//         if (ref.current) {
//           ref.current.value = "";
//         }
//       });
//     }
//   };

//   const paginate = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   useEffect(() => {
//     const fetchWindowsData = async () => {
//       if (noWindows) {
//         try {
//           setIsLoading(true);
//           const response = await fetch(
//             `${apiRoot}/api/rooms?projectId=${projectID}&currentPage=${currentPage}`,
//             // `${apiRoot}/api/surfaces?projectId=${projectID}&currentPage=${currentPage}`,
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             }
//           );
//           const data = await response.json();
//           setWindowsData(data.list || []);
//           setTotalPages(data.pages || 1);
//           setIsLoading(false);
//         } catch (error) {
//           console.error("Error fetching windows data:", error);
//           setIsLoading(false);
//         }
//       } else {
//         setIsLoading(false);
//       }
//     };

//     const fetchProjects = async () => {
//       try {
//         const response = await fetch(`${apiRootSecond}/api/projects/`, {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         });

//         // console.log("Response status:", response.status);
//         // console.log("Response headers:", response.headers);
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         const formattedProjects = data.map((project) => ({
//           value: project.id,
//           label: project.name,
//         }));

//         setProjects(formattedProjects);
//       } catch (error) {
//         console.error("Error fetching projects:", error);
//       }
//     };

//     fetchWindowsData();
//     fetchProjects();
//   }, [noWindows, projectID, token, currentPage]);

//   return (
//     <div>
//       <WithNavbar />
//       <TopNavbar />
//       <div className="upload-container">
//         <h2>{heading2}</h2>
//         <ToastContainer position="top-right" autoClose={5000} />
//         {/* <ToastContainer /> */}
//         <div>
//           {isLoading ? (
//             <div>Loading Rooms...</div>
//           ) : (
//             <div>
//               <div
//                 style={{
//                   border: "2px dashed #000",
//                   padding: "10px",
//                   margin: "20px",
//                 }}
//               >
//                 <h3>Available Rooms</h3>
//                 <div className="windows-table-container">
//                   <table className="windows-table">
//                     <thead>
//                       <tr>
//                         <th>Name</th>
//                         <th>Area (m²)</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {windowsData.map((item, index) => (
//                         <tr key={item.room.id}>
//                           <td>{item.room.name}</td>
//                           <td>
//                             {item.room.area ||
//                               item.room.volume ||
//                               item.room.occupancy}
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//                 <div className="pagination-container">
//                   <button
//                     onClick={() => paginate(currentPage - 1)}
//                     disabled={currentPage === 1}
//                     className="pagination-button"
//                   >
//                     &larr; Prev
//                   </button>
//                   <span className="pagination-info">
//                     Page {currentPage} of {totalPages}
//                   </span>
//                   <button
//                     onClick={() => paginate(currentPage + 1)}
//                     disabled={currentPage === totalPages}
//                     className="pagination-button"
//                   >
//                     Next &rarr;
//                   </button>
//                 </div>
//               </div>

//               <div className="action-buttons">
//                 <button
//                   onClick={() => setActiveSection("insert")}
//                   className="insert-button"
//                 >
//                   Insert New Rooms
//                 </button>
//                 {/* <button
//                   onClick={() => setActiveSection("update")}
//                   className="upload-button"
//                 >
//                   Update Rooms
//                 </button> */}
//                 <button
//                   onClick={() => setActiveSection("copy")}
//                   className="upload-button"
//                 >
//                   Copy Rooms from Another Project
//                 </button>
//               </div>
//             </div>
//           )}
//         </div>

//         {(activeSection === "insert" || activeSection === "update") && (
//           <div className="file-upload-class">
//             <h3>
//               {activeSection === "insert" ? "Insert New Rooms" : "Update Rooms"}
//             </h3>
//             <div>
//               <FileSingleComponent
//                 heading1="Upload Your Room Zip File"
//                 heading2="Digital Twin File Uploader"
//                 fileUploadComponents={[
//                   {
//                     label: "Rooms Zip File",
//                     id: "scene-setup",
//                     uploadUrl: `${apiRoot}/rooms/upload_excel?project_id=${projectID}&user_id=${userId}`,
//                     // uploadUrl: `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userId}&file_type=window`,
//                   },
//                 ]}
//                 apiText="here is the floor list available on db, please consider your Rooms file name should have the floor name at the end of its name"
//                 // apiContextArg={floorList}
//               />
//             </div>
//           </div>
//         )}
//         {activeSection === "copy" && (
//           <div className="copy-windows-section">
//             <h3>Copy Rooms from Another Project</h3>
//             <select
//               value={selectedProject}
//               onChange={(e) => setSelectedProject(e.target.value)}
//             >
//               <option value="">Select a project</option>
//               {projects.map((project) => (
//                 <option key={project.value} value={project.value}>
//                   {project.label}
//                 </option>
//               ))}
//             </select>
//             <button onClick={handleCopyWindows} className="copy-button">
//               Copy Rooms
//             </button>

//             {apiResponse && (
//               <div className="api-response">
//                 <h4>API Response:</h4>
//                 <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default WindowUploadFileComponent;
// import React, { useState, useEffect } from "react";
// import "./FileUpload.css";
// import { useNavigate } from "react-router-dom";
// import WithNavbar from "./LeftNavbar";
// import TopNavbar from "./TopNavbar";
// import { ToastContainer, toast } from "react-toastify";
// import FileUploadWithoutFeatureSingleComponent from "./FileUploadWithoutFeatureComponents";
// import "react-toastify/dist/ReactToastify.css";
// import FileUploadSingleComponentEnergyData from "./FileUploadSingleComponentEnergyData";

// const WindowUploadFileComponent = ({
//   heading1,
//   heading2,
//   fileUploadComponents,
//   showEdit,
//   editText,
//   showEditHover,
//   apiContextArg,
//   apiText,
//   noWindows,
// }) => {
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [windowCount, setWindowCount] = useState(0);
//   const [windowsData, setWindowsData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [selectedProject, setSelectedProject] = useState("");
//   const [projects, setProjects] = useState([]);
//   const [activeSection, setActiveSection] = useState("");
//   const [apiResponse, setApiResponse] = useState(null);
//   const [file, setFile] = useState(null); // State to store the selected file
//   const [orderedFloors, setOrderedFloors] = useState([]); // Example state for ordered floors
//   const [xlsxFileNames, setXlsxFileNames] = useState([]); // Example state for file names

//   const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
//   const userId = localStorage.getItem("userID");
//   const token = localStorage.getItem("token");
//   const projectID = localStorage.getItem("projectID");
//   const apiRootSecond = process.env.REACT_APP_API_ROOT;
//   const navigate = useNavigate();
//   const handleFileSelect = (selectedFile) => {
//     console.log("Selected file:", selectedFile); // Log the file to confirm it's being selected
//     setFile(selectedFile);
//   };
//   //   const handleFileSelect = (selectedFile) => {
//   //     setFile(selectedFile);
//   //   };

//   //   const handleFileSelect = (id, file) => {
//   //     setFileData((prevData) => ({ ...prevData, [id]: file }));
//   //   };

//   const handleCopyWindows = async () => {
//     console.log("inja");

//     toast.info("Copying windows, please wait...");

//     if (!selectedProject) {
//       toast.error("Please select a project first.");
//       return;
//     }
//     console.log(selectedProject);
//     console.log(
//       `${apiRoot}/api/rooms/copy?oldProjectId=${selectedProject}&newProjectId=${projectID}`
//     );

//     try {
//       const response = await fetch(
//         `${apiRoot}/api/rooms/copy?oldProjectId=${selectedProject}&newProjectId=${projectID}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//           body: JSON.stringify({
//             sourceProjectId: selectedProject,
//             targetProjectId: projectID,
//           }),
//         }
//       );

//       const data = await response.json();
//       setApiResponse(data);
//       console.log("kkkkkkkkk");

//       if (response.ok) {
//         console.log("jflajlfjlajl");
//         toast.success("Windows copied successfully!");
//       } else {
//         toast.error(`Error: ${data.message || "Failed to copy windows."}`);
//       }

//       console.log(
//         `Windows copied successfully from project ID: ${selectedProject}`
//       );
//     } catch (error) {
//       console.error("Error copying windows:", error);
//       toast.error("Failed to copy windows. Please try again.");
//     }
//   };
//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!file) {
//       toast.error("Please select a file to upload.");
//       return;
//     }

//     const uploadFormData = new FormData();
//     uploadFormData.append("file", file);

//     // Log the form data
//     for (let [key, value] of uploadFormData.entries()) {
//       console.log(`${key}: ${value.name || value}`);
//     }

//     try {
//       let uploadUrl = `${apiRoot}/api/rooms/uploadrooms`;

//       const orderedFloorsJson = JSON.stringify(orderedFloors);
//       const modifiedXlsxFileNames = xlsxFileNames.map((name) =>
//         name.slice(0, -".xlsx".length)
//       );
//       const lastParts = modifiedXlsxFileNames.map((name) => {
//         const parts = name.split("/");
//         return parts[parts.length - 1];
//       });

//       const scenario_order = JSON.stringify(lastParts);

//       const queryParams = new URLSearchParams({
//         projectId: projectID,
//         user_id: userId,
//         file_type: "thermal",
//         values: orderedFloorsJson,
//         scenario_order: JSON.stringify(xlsxFileNames),
//       }).toString();

//       uploadUrl += "?" + queryParams;

//       // Log the URL and query parameters
//       console.log("Upload URL:", uploadUrl);

//       const res = await fetch(uploadUrl, {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         body: uploadFormData,
//       });

//       const data = await res.json();

//       // Log the response
//       console.log("Response:", data);

//       if (res.ok) {
//         toast.success("File uploaded successfully!");
//         setFile(null); // Clear the file input after successful upload
//       } else {
//         toast.error(`Error: ${data.message || "Failed to upload file."}`);
//       }
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       toast.error("Failed to upload file. Please try again.");
//     }
//   };
//   //   const handleSubmit = async (event) => {
//   //     event.preventDefault();

//   //     if (!file) {
//   //       toast.error("Please select a file to upload.");
//   //       return;
//   //     }

//   //     const uploadFormData = new FormData();
//   //     uploadFormData.append("file", file);

//   //     try {
//   //       let uploadUrl = `${apiRoot}/api/rooms/upload_excel`;

//   //       const orderedFloorsJson = JSON.stringify(orderedFloors);
//   //       const modifiedXlsxFileNames = xlsxFileNames.map((name) =>
//   //         name.slice(0, -".xlsx".length)
//   //       );
//   //       const lastParts = modifiedXlsxFileNames.map((name) => {
//   //         const parts = name.split("/");
//   //         return parts[parts.length - 1];
//   //       });

//   //       const scenario_order = JSON.stringify(lastParts);

//   //       const queryParams = new URLSearchParams({
//   //         projectId: projectID,
//   //         user_id: userId,
//   //         // file_type: "thermal",
//   //         // values: orderedFloorsJson,
//   //         // scenario_order: JSON.stringify(xlsxFileNames),
//   //       }).toString();

//   //       uploadUrl += "?" + queryParams;

//   //       const res = await fetch(uploadUrl, {
//   //         method: "POST",
//   //         headers: {
//   //           Authorization: `Bearer ${token}`,
//   //         },
//   //         body: uploadFormData,
//   //       });

//   //       const data = await res.json();

//   //       if (res.ok) {
//   //         toast.success("File uploaded successfully!");
//   //         setFile(null); // Clear the file input after successful upload
//   //       } else {
//   //         toast.error(`Error: ${data.message || "Failed to upload file."}`);
//   //       }
//   //     } catch (error) {
//   //       console.error("Error uploading file:", error);
//   //       toast.error("Failed to upload file. Please try again.");
//   //     }
//   //   };

//   const paginate = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   useEffect(() => {
//     const fetchWindowsData = async () => {
//       if (noWindows) {
//         try {
//           setIsLoading(true);
//           const response = await fetch(
//             `${apiRoot}/api/rooms?projectId=${projectID}&currentPage=${currentPage}`,
//             {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             }
//           );
//           const data = await response.json();
//           setWindowsData(data.list || []);
//           setTotalPages(data.pages || 1);
//           setIsLoading(false);
//         } catch (error) {
//           console.error("Error fetching rooms data:", error);
//           setIsLoading(false);
//         }
//       } else {
//         setIsLoading(false);
//       }
//     };

//     const fetchProjects = async () => {
//       try {
//         const response = await fetch(`${apiRootSecond}/api/projects/`, {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();
//         const formattedProjects = data.map((project) => ({
//           value: project.id,
//           label: project.name,
//         }));

//         setProjects(formattedProjects);
//       } catch (error) {
//         console.error("Error fetching projects:", error);
//       }
//     };

//     fetchWindowsData();
//     fetchProjects();
//   }, [noWindows, projectID, token, currentPage]);

//   return (
//     <div>
//       <WithNavbar />
//       <TopNavbar />
//       <div className="upload-container">
//         <h2>{heading2}</h2>
//         <ToastContainer position="top-right" autoClose={5000} />

//         <div>
//           {isLoading ? (
//             <div>Loading Rooms...</div>
//           ) : (
//             <div>
//               <div
//                 style={{
//                   border: "2px dashed #000",
//                   padding: "10px",
//                   margin: "20px",
//                 }}
//               >
//                 <h3>Available Rooms</h3>
//                 <div className="windows-table-container">
//                   <table className="windows-table">
//                     <thead>
//                       <tr>
//                         <th>Name</th>
//                         <th>Area (m²)</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {windowsData.map((item) => (
//                         <tr key={item.room.id}>
//                           <td>{item.room.name}</td>
//                           <td>
//                             {item.room.area ||
//                               item.room.volume ||
//                               item.room.occupancy}
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//                 <div className="pagination-container">
//                   <button
//                     onClick={() => paginate(currentPage - 1)}
//                     disabled={currentPage === 1}
//                     className="pagination-button"
//                   >
//                     &larr; Prev
//                   </button>
//                   <span className="pagination-info">
//                     Page {currentPage} of {totalPages}
//                   </span>
//                   <button
//                     onClick={() => paginate(currentPage + 1)}
//                     disabled={currentPage === totalPages}
//                     className="pagination-button"
//                   >
//                     Next &rarr;
//                   </button>
//                 </div>
//               </div>

//               <div className="action-buttons">
//                 <button
//                   onClick={() => setActiveSection("insert")}
//                   className="insert-button"
//                 >
//                   Insert New Rooms
//                 </button>
//                 <button
//                   onClick={() => setActiveSection("copy")}
//                   className="upload-button"
//                 >
//                   Copy Rooms from Another Project
//                 </button>
//               </div>
//             </div>
//           )}
//         </div>

//         {activeSection === "insert" && (
//           <div className="file-upload-class">
//             <h3>Insert New Rooms</h3>
//             <div>
//               <FileUploadWithoutFeatureSingleComponent
//                 label="Energy Data Setup"
//                 id="project-platform"
//                 // isZip={true}
//                 // onZipContentDetected={(content) =>
//                 //   console.log("Zip content detected:", content)
//                 // }
//                 energyData={true}
//                 onFileSelect={handleFileSelect}
//               />
//               <button onClick={handleSubmit} className="upload-button">
//                 Upload Rooms
//               </button>
//             </div>
//           </div>
//         )}

//         {activeSection === "copy" && (
//           <div className="copy-windows-section">
//             <h3>Copy Rooms from Another Project</h3>
//             <select
//               value={selectedProject}
//               onChange={(e) => setSelectedProject(e.target.value)}
//             >
//               <option value="">Select a project</option>
//               {projects.map((project) => (
//                 <option key={project.value} value={project.value}>
//                   {project.label}
//                 </option>
//               ))}
//             </select>
//             <button onClick={handleCopyWindows} className="copy-button">
//               Copy Rooms
//             </button>

//             {apiResponse && (
//               <div className="api-response">
//                 <h4>API Response:</h4>
//                 <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default WindowUploadFileComponent;
import React, { useState, useEffect } from "react";
import "./FileUpload.css";
import { useNavigate } from "react-router-dom";
import WithNavbar from "./LeftNavbar";
import TopNavbar from "./TopNavbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomFileUpload from "./CustomFileUpload";

const WindowUploadFileComponent = ({
  heading1,
  heading2,
  fileUploadComponents,
  showEdit,
  editText,
  showEditHover,
  apiContextArg,
  apiText,
  noWindows,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [windowCount, setWindowCount] = useState(0);
  const [windowsData, setWindowsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedProject, setSelectedProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [activeSection, setActiveSection] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [file, setFile] = useState(null); // State to store the selected file
  const [orderedFloors, setOrderedFloors] = useState([]); // Example state for ordered floors
  const [xlsxFileNames, setXlsxFileNames] = useState([]); // Example state for file names

  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const userId = localStorage.getItem("userID");
  const token = localStorage.getItem("token");
  const projectID = localStorage.getItem("projectID");
  const apiRootSecond = process.env.REACT_APP_API_ROOT;
  const navigate = useNavigate();

  const handleFileSelect = (selectedFile) => {
    console.log("Selected file:", selectedFile); // Log the file to confirm it's being selected
    setFile(selectedFile);
  };
  const handleCopyWindows = async () => {
    console.log("inja");

    toast.info("Copying windows, please wait...");

    if (!selectedProject) {
      toast.error("Please select a project first.");
      return;
    }
    console.log(selectedProject);
    console.log(
      `${apiRoot}/api/rooms/copy?oldProjectId=${selectedProject}&newProjectId=${projectID}`
    );

    try {
      const response = await fetch(
        `${apiRoot}/api/rooms/copy?oldProjectId=${selectedProject}&newProjectId=${projectID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sourceProjectId: selectedProject,
            targetProjectId: projectID,
          }),
        }
      );

      const data = await response.json();
      setApiResponse(data);
      console.log("kkkkkkkkk");

      if (response.ok) {
        console.log("jflajlfjlajl");
        toast.success("Windows copied successfully!");
      } else {
        toast.error(`Error: ${data.message || "Failed to copy windows."}`);
      }

      console.log(
        `Windows copied successfully from project ID: ${selectedProject}`
      );
    } catch (error) {
      console.error("Error copying windows:", error);
      toast.error("Failed to copy windows. Please try again.");
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    const uploadFormData = new FormData();
    uploadFormData.append("file", file);

    // Log the form data
    for (let [key, value] of uploadFormData.entries()) {
      console.log(`${key}: ${value.name || value}`);
    }

    try {
      let uploadUrl = `${apiRoot}/api/rooms/upload_excel`;

      const orderedFloorsJson = JSON.stringify(orderedFloors);
      const modifiedXlsxFileNames = xlsxFileNames.map((name) =>
        name.slice(0, -".xlsx".length)
      );
      const lastParts = modifiedXlsxFileNames.map((name) => {
        const parts = name.split("/");
        return parts[parts.length - 1];
      });

      const scenario_order = JSON.stringify(lastParts);

      const queryParams = new URLSearchParams({
        projectId: projectID,
        // user_id: userId,
        // file_type: "thermal",
        // values: orderedFloorsJson,
        // scenario_order: JSON.stringify(xlsxFileNames),
      }).toString();

      uploadUrl += "?" + queryParams;

      // Log the URL and query parameters
      console.log("Upload URL:", uploadUrl);

      const res = await fetch(uploadUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: uploadFormData,
      });

      const data = await res.json();

      // Log the response
      console.log("Response:", data);

      if (res.ok) {
        toast.success("File uploaded successfully!");
        setFile(null); // Clear the file input after successful upload
      } else {
        toast.error(`Error: ${data.message || "Failed to upload file."}`);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Failed to upload file. Please try again.");
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const fetchWindowsData = async () => {
      if (noWindows) {
        try {
          setIsLoading(true);
          const response = await fetch(
            `${apiRoot}/api/rooms?projectId=${projectID}&currentPage=${currentPage}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setWindowsData(data.list || []);
          setTotalPages(data.pages || 1);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching rooms data:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await fetch(`${apiRootSecond}/api/projects/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const formattedProjects = data.map((project) => ({
          value: project.id,
          label: project.name,
        }));

        setProjects(formattedProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchWindowsData();
    fetchProjects();
  }, [noWindows, projectID, token, currentPage]);

  return (
    <div>
      <WithNavbar />
      <TopNavbar />
      <div className="upload-container">
        <h2>{heading2}</h2>
        <ToastContainer position="top-right" autoClose={5000} />

        <div>
          {isLoading ? (
            <div>Loading Rooms...</div>
          ) : (
            <div>
              <div
                style={{
                  border: "2px dashed #000",
                  padding: "10px",
                  margin: "20px",
                }}
              >
                <h3>Available Rooms</h3>
                <div className="windows-table-container">
                  <table className="windows-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Area (m²)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {windowsData.map((item) => (
                        <tr key={item.room.id}>
                          <td>{item.room.name}</td>
                          <td>
                            {item.room.area ||
                              item.room.volume ||
                              item.room.occupancy}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-container">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="pagination-button"
                  >
                    &larr; Prev
                  </button>
                  <span className="pagination-info">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                  >
                    Next &rarr;
                  </button>
                </div>
              </div>

              <div className="action-buttons">
                <button
                  onClick={() => setActiveSection("insert")}
                  className="insert-button"
                >
                  Insert New Rooms
                </button>
                <button
                  onClick={() => setActiveSection("copy")}
                  className="upload-button"
                >
                  Copy Rooms from Another Project
                </button>
              </div>
            </div>
          )}
        </div>

        {activeSection === "insert" && (
          <div className="file-upload-class">
            <h3>Insert New Rooms</h3>
            <div>
              <CustomFileUpload
                label="Select Room Data File"
                onFileSelect={handleFileSelect}
              />
              <button onClick={handleSubmit} className="upload-button">
                Upload Rooms
              </button>
            </div>
          </div>
        )}

        {activeSection === "copy" && (
          <div className="copy-windows-section">
            <h3>Copy Rooms from Another Project</h3>
            <select
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
            >
              <option value="">Select a project</option>
              {projects.map((project) => (
                <option key={project.value} value={project.value}>
                  {project.label}
                </option>
              ))}
            </select>
            <button onClick={handleCopyWindows} className="copy-button">
              Copy Rooms
            </button>

            {apiResponse && (
              <div className="api-response">
                <h4>API Response:</h4>
                <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WindowUploadFileComponent;
