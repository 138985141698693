import React, { useState, useEffect } from "react";
// import React, { useState } from "react";
import "./FileUpload.css";
import FileUploadSingleComponentEnergyData from "./FileUploadSingleComponentEnergyData";
import Sidebar from "./Sidebar";
import FileUploadSingleComponent from "./FileUploadSingleComponent";
import TopNavbar from "./TopNavbar";
import WithNavbar from "./LeftNavbar";

const EnergyDataInsertion = () => {
  const [xlsxFileNames, setXlsxFileNames] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const projectID = localStorage.getItem("projectID");
  const userID = localStorage.getItem("userID");
  // const apiRoot = process.env.REACT_APP_API_ROOT;
  const [windowsData, setWindowsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const apiRoot = process.env.REACT_APP_API_ROOT_OPERATIONAL;
  const apiRootSecond = process.env.REACT_APP_API_ROOT;
  const handleFileSelect = (id, selectedFile) => {
    setFile(selectedFile);
  };
  // useEffect(() => {
  //   const fetchWindowsData = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await fetch(
  //         `${apiRoot}/api/rooms?projectId=${projectID}&currentPage=${currentPage}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           },
  //         }
  //       );
  //       const data = await response.json();
  //       setWindowsData(data.list || []);
  //       setTotalPages(data.pages || 0);
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching windows data:", error);
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchWindowsData();
  // }, [apiRoot, projectID, currentPage]);

  const [file, setFile] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      // alert("Please select a file to upload.");
      return;
    }
    const uploadFormData = new FormData();
    uploadFormData.append("file", file);
    for (let [key, value] of uploadFormData.entries()) {
      console.log(key, value);
    }

    try {
      var uploadUrl = `${apiRoot}/api/rooms/uploadrooms`;
      const orderedFloorsJson = JSON.stringify(orderedFloors);
      // const scenario_order = JSON.stringify(
      //   xlsxFileNames.map((name) => name.slice(0, -".xlsx".length))
      // );
      const modifiedXlsxFileNames = xlsxFileNames.map((name) =>
        name.slice(0, -".xlsx".length)
      );

      const lastParts = modifiedXlsxFileNames.map((name) => {
        const parts = name.split("/");
        return parts[parts.length - 1];
      });

      const scenario_order = JSON.stringify(lastParts);
      console.log("scenario_order");
      console.log(scenario_order);
      const queryParams = new URLSearchParams({
        project_id: projectID,
        user_id: userID,
        file_type: "thermal",
        values: orderedFloorsJson,
        scenario_order: JSON.stringify(xlsxFileNames),
      }).toString();
      uploadUrl += "?" + queryParams;

      const res = await fetch(uploadUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: uploadFormData,
      });

      let projectInfoUrl = `${apiRootSecond}/api/project_info/?project_id=${projectID}`;
      const getResponse = await fetch(projectInfoUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      let projectInfoMethod;
      let id;
      if (getResponse.ok) {
        const existingData = await getResponse.json();
        id = existingData[0].id;
        projectInfoUrl = `${apiRootSecond}/api/project_info/${id}/`;
        projectInfoMethod = "PATCH";
      } else {
        projectInfoUrl = `${apiRootSecond}/api/project_info/`;
        projectInfoMethod = "POST";
      }

      let formData = new FormData();
      formData.append("project_id", projectID);
      formData.append("energy_value_list", JSON.stringify(orderedFloors));
      formData.append("all_scenario_list", scenario_order);
      formData.append("scenario_description", JSON.stringify(orderedFloors));
      console.log(formData);
      const projectInfoResponse = await fetch(projectInfoUrl, {
        method: projectInfoMethod,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });

      if (!projectInfoResponse.ok) {
        throw new Error(`Error: ${projectInfoResponse.statusText}`);
      }

      var projectInfoResponseData = await projectInfoResponse.json();

      // alert("Project info updated successfully!");
    } catch (error) {
      console.error("Error:", error);
      // alert("An error occurred. Please try again.");
    }
  };

  const [availableFloors, setAvailableFloors] = useState([]);
  const [orderedFloors, setOrderedFloors] = useState([]);
  const addToOrderedFloors = (floor) => {
    if (!orderedFloors.includes(floor)) {
      setOrderedFloors([...orderedFloors, floor]);
      setAvailableFloors(availableFloors.filter((f) => f !== floor));
    }
  };

  const removeFromOrderedFloors = (floor) => {
    setOrderedFloors(orderedFloors.filter((f) => f !== floor));
    setAvailableFloors([...availableFloors, floor]);
  };

  const [excelColumnNames, setExcelColumnNames] = useState([]);

  const handleEnergyZipContentDetected = (columnNames, fileNames) => {
    setExcelColumnNames((prevNames) => {
      const allNames = new Set([...prevNames, ...columnNames]);
      return Array.from(allNames);
    });
    setXlsxFileNames(fileNames);
  };

  return (
    <div>
      <WithNavbar />
      <TopNavbar />

      <div className="upload-container">
        <h2>Digital Twin Viewer Insertion</h2>

        <form onSubmit={handleSubmit}>
          <div>
            <h1>Set up Energy data of project here</h1>
          </div>

          <div className="file-upload-class">
            {/* {totalPages > 0 && <h2>Here is the list of available rooms</h2>}
            {isLoading ? (
              <div>Loading Rooms...</div>
            ) : totalPages > 0 ? (
              <div
                style={{
                  border: "2px dashed #000",
                  padding: "10px",
                  margin: "20px",
                }}
              >
                <h3>Available Rooms</h3>
                <div className="windows-table-container">
                  <table className="windows-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Area (m²)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {windowsData.map((item, index) => (
                        <tr key={item.room.id}>
                          <td>{item.room.name}</td>
                          <td>
                            {item.room.area ||
                              item.room.volume ||
                              item.room.occupancy}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-container">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className="pagination-button"
                  >
                    &larr; Prev
                  </button>
                  <span className="pagination-info">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    className="pagination-button"
                  >
                    Next &rarr;
                  </button>
                </div>
              </div>
            ) : (
              <div>No rooms available.</div>
            )} */}
            <h2>Upload Your Energy Data here</h2>
            <FileUploadSingleComponentEnergyData
              label="Energy Data Setup"
              id="project-platform"
              isZip={true}
              onZipContentDetected={handleEnergyZipContentDetected}
              energyData={true}
              onFileSelect={handleFileSelect}
            />
            {excelColumnNames.length > 0 && (
              <div>
                <h2>Extracted Column Names:</h2>
                <ul>
                  {excelColumnNames.map((name, index) => (
                    <li key={index}>{name}</li>
                  ))}
                </ul>
              </div>
            )}

            <div className="floors-management-container">
              <div className="floors-list">
                <h2>Available Energy Values:</h2>
                <select multiple size="10" className="textarea-class">
                  {excelColumnNames.map((floor, index) => (
                    <option
                      key={index}
                      value={floor}
                      onDoubleClick={() => addToOrderedFloors(floor)}
                    >
                      {floor}
                    </option>
                  ))}
                </select>
              </div>
              <div className="floor-buttons">
                <button
                  type="button"
                  onClick={() => {
                    const selectElement = document.querySelector(
                      ".floors-list select"
                    );
                    if (selectElement.selectedOptions.length > 0) {
                      const selectedFloor =
                        selectElement.selectedOptions[0].value;
                      addToOrderedFloors(selectedFloor);
                    }
                  }}
                >
                  Add →
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const selectElement = document.querySelector(
                      ".ordered-floors-list select"
                    );
                    if (selectElement.selectedOptions.length > 0) {
                      const selectedFloor =
                        selectElement.selectedOptions[0].value;
                      removeFromOrderedFloors(selectedFloor);
                    }
                  }}
                >
                  ← Remove
                </button>
              </div>
              <div className="floors-list ordered-floors-list">
                <h2>Ordered Floors:</h2>
                <select multiple size="10" className="textarea-class">
                  {orderedFloors.map((floor, index) => (
                    <option
                      key={index}
                      value={floor}
                      onDoubleClick={() => removeFromOrderedFloors(floor)}
                    >
                      {floor}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div>
            <h2>please insert your floor order</h2>
          </div>
          <div>
            <input
              type="checkbox"
              id="editSceneOrder"
              checked={isEditMode}
              onChange={(e) => setIsEditMode(e.target.checked)}
            />
            <label htmlFor="editSceneOrder">Edit Scene Order</label>
          </div>

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default EnergyDataInsertion;
