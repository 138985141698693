import React, { useState } from "react";
import "./FileUpload.css";
import FileUploadSingleComponent from "./FileUploadSingleComponent";
import TopNavbar from "./TopNavbar";
import WithNavbar from "./LeftNavbar";
import { useEffect } from "react";

const SceneSetupInsertion = () => {
  const apiRoot = process.env.REACT_APP_API_ROOT;
  const [zipFileNames, setZipFileNames] = useState([]);

  const projectID = localStorage.getItem("projectID");
  console.log("projectid");
  console.log(projectID);
  const userID = localStorage.getItem("userID");

  const token = localStorage.getItem("token");

  const [ceilingList, setCeilingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [externalList, setExternalList] = useState([]);
  const [internalList, setInternalList] = useState([]);
  const [doorsList, setDoorsList] = useState([]);
  const [stairsList, setStairsList] = useState([]);
  const [pvList, setPVList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${apiRoot}/api/project_info/?project_id=${projectID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        console.error("Failed to fetch data:", response.statusText);
        return;
      }
      const data = await response.json();
      if (data.length > 0 && data[0]) {
        try {
          setCeilingList(
            data[0].ceiling_list ? JSON.parse(data[0].ceiling_list) : []
          );
        } catch (e) {
          console.error("Error parsing ceiling_list:", e);
          setCeilingList([]);
        }

        try {
          setFloorList(
            data[0].floor_list ? JSON.parse(data[0].floor_list) : []
          );
        } catch (e) {
          console.error("Error parsing floor_list:", e);
          setFloorList([]);
        }

        try {
          setExternalList(
            data[0].external_list ? JSON.parse(data[0].external_list) : []
          );
        } catch (e) {
          console.error("Error parsing external_list:", e);
          setExternalList([]);
        }

        try {
          setInternalList(
            data[0].internal_list ? JSON.parse(data[0].internal_list) : []
          );
        } catch (e) {
          console.error("Error parsing internal_list:", e);
          setInternalList([]);
        }

        try {
          setDoorsList(data[0].doors ? JSON.parse(data[0].doors) : []);
        } catch (e) {
          console.error("Error parsing doors:", e);
          setDoorsList([]);
        }

        try {
          setStairsList(data[0].stairs ? JSON.parse(data[0].stairs) : []);
        } catch (e) {
          console.error("Error parsing stairs:", e);
          setStairsList([]);
        }
        try {
          setPVList(data[0].pv_panels ? JSON.parse(data[0].pv_panels) : []);
        } catch (e) {
          console.error("Error parsing pv_panels:", e);
          setPVList([]);
        }
      } else {
        // Handle the case where data is empty or data[0] is undefined
        console.warn("No data available");
        setCeilingList([]);
        setFloorList([]);
        setExternalList([]);
        setInternalList([]);
        setDoorsList([]);
        setStairsList([]);
        setPVList([]);
      }
      // if (data.length > 0) {
      //   setCeilingList(JSON.parse(data[0].ceiling_list));
      //   setFloorList(JSON.parse(data[0].floor_list));
      //   setExternalList(JSON.parse(data[0].external_list));
      //   setInternalList(JSON.parse(data[0].internal_list));
      //   setDoorsList(JSON.parse(data[0].doors));
      //   setStairsList(JSON.parse(data[0].stairs));
      // }
    };

    fetchData();
  }, [apiRoot, projectID, token]);

  const categorizeFiles = (fileNames, orderedFloors) => {
    console.log(fileNames);
    // const facadeRegex = /(.+?)_facade.+\.glb/i;
    const facadeRegex = /(.+?)_facade.*\.glb/i;
    // const floorRegex = /(.+?)_floor.+(?:s)?\.glb/i;
    const floorRegex = /(.+?)_floors.*\.glb/i;
    // const interiorRegex = /(.+?)_(interior|internal|inner)_walls\.glb/i;
    const interiorRegex = /(.+?)_(interior|internal|inner).*\.glb/i;

    // const pvRegex = /PV(_panels)?\.glb/i;
    const pvRegex = /(.+?)_(pvs|PVs|pv).*\.glb/i;

    // const pvRegex = /PV(s|_panels)?\.glb/i;
    const testFileName = "PV.glb";
    console.log(pvRegex.test(testFileName));
    const stairsRegex = /(\d+\.\w+)?_stairs.*\.glb/i;
    // const interiorRegex = /(.+?)_(interior|internal|inner)_walls\.glb/i;
    const doorsRegex = /(\d+\.\w+)?_doors.*\.glb/i;

    const facadeList = [];
    const floorList = [];
    const interiorList = [];
    const pvPanelList = [];
    const stairsList = [];
    const doorsList = [];

    const relevantFileNames = fileNames.filter(
      (fileName) =>
        !fileName.includes("__MACOSX") &&
        !fileName.includes(".DS_Store") &&
        fileName.endsWith(".glb")
    );
    console.log("ordered floor", orderedFloors);

    orderedFloors.forEach((floor) => {
      relevantFileNames.forEach((fileName) => {
        const parts = fileName.split("/");
        const baseFileName = parts.pop();
        // console.log(baseFileName);

        if (baseFileName.toLowerCase().includes(floor.toLowerCase())) {
          // console.log("floor", floor);
          // console.log("filename", baseFileName);
          // console.log("bali");
          if (facadeRegex.test(baseFileName)) {
            // console.log("hasaaat");
            facadeList.push(baseFileName.replace(".glb", ""));
          } else if (floorRegex.test(baseFileName)) {
            floorList.push(baseFileName.replace(".glb", ""));
          } else if (interiorRegex.test(baseFileName)) {
            interiorList.push(baseFileName.replace(".glb", ""));
          } else if (pvRegex.test(baseFileName)) {
            pvPanelList.push(baseFileName.replace(".glb", ""));
          } else if (doorsRegex.test(baseFileName)) {
            doorsList.push(baseFileName.replace(".glb", ""));
          } else if (stairsRegex.test(baseFileName)) {
            stairsList.push(baseFileName.replace(".glb", ""));
          }
        }
      });
    });

    // console.log(stairsList);
    return {
      facadeList,
      floorList,
      interiorList,
      stairsList,
      doorsList,
      pvPanelList,
    };
  };

  const [isEditMode, setIsEditMode] = useState(false);

  const [zipFile, setZipFile] = useState(null);
  const handleZipFileChange = (event) => {
    setZipFile(event.target.files[0]);
  };
  const handleFileSelect = (selectedFile) => {
    setZipFile(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!zipFile) {
      alert("Please upload a zip file.");
      return;
    }

    const {
      facadeList,
      floorList,
      interiorList,
      stairsList,
      doorsList,
      pvPanelList,
    } = categorizeFiles(zipFileNames, orderedFloors);
    // console.log("internal list");
    // console.log(interiorList);

    const uploadFormData = new FormData();
    uploadFormData.append("file", zipFile);

    try {
      const uploadUrl = `${apiRoot}/project/setup/?project_id=${projectID}&user_id=${userID}&file_type=glb`;
      await fetch(uploadUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: uploadFormData,
      });
      // console.log("done");
      let projectInfoUrl = `${apiRoot}/api/project_info/`;
      let projectInfoMethod = "POST";

      let formData = new FormData();
      formData.append("project_id", projectID);
      formData.append("floor_list", JSON.stringify(orderedFloors));
      formData.append("internal_list", JSON.stringify(interiorList));
      formData.append("ceiling_list", JSON.stringify(floorList));
      formData.append("external_list", JSON.stringify(facadeList));
      // formData.append("all_scenario_list", JSON.stringify(["test"]));
      formData.append("doors", JSON.stringify(doorsList));
      formData.append("stairs", JSON.stringify(stairsList));
      formData.append("pv_panels", JSON.stringify(["PV", "PVs"]));
      // formData.append("pv_panels", JSON.stringify(pvPanelList));
      console.log(pvPanelList);

      const getResponse = await fetch(
        `${apiRoot}/api/project_info/?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (getResponse.ok) {
        const existingData = await getResponse.json();
        if (existingData && existingData.length > 0) {
          console.log("Existing data found, switching to edit mode");
          var id = existingData[0].id;
          if (isEditMode) {
            projectInfoUrl = `${apiRoot}/api/project_info/${id}/`;
            projectInfoMethod = "PATCH";
          }
        } else {
          console.log("No existing data found, proceeding with POST");
        }
      } else {
        throw new Error(
          `Error retrieving existing data: ${getResponse.statusText}`
        );
      }

      const projectInfoResponse = await fetch(projectInfoUrl, {
        method: projectInfoMethod,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });

      if (!projectInfoResponse.ok) {
        throw new Error(`Error: ${projectInfoResponse.statusText}`);
      }

      const projectInfoResponseData = await projectInfoResponse.json();
      // console.log(projectInfoResponseData);
      alert("Project info updated successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const [floorOptions, setFloorOptions] = useState([]);

  const handleZipContentDetected = (fileNames) => {
    const floorNames = extractFloorNames(fileNames);
    setFloorOptions([...new Set(floorNames)]);
    setZipFileNames(fileNames);
  };
  const extractFloorNames = (filePaths) => {
    const uniqueFloorNames = new Set();

    filePaths.forEach((path) => {
      if (!path.includes("__MACOSX") && !path.includes(".DS_Store")) {
        const parts = path.split("/").filter((part) => part);
        if (parts.length >= 2) {
          const floorName = parts[parts.length - 2];
          uniqueFloorNames.add(floorName);
        }
      }
    });

    return Array.from(uniqueFloorNames);
  };

  const [availableFloors, setAvailableFloors] = useState([]);
  const [orderedFloors, setOrderedFloors] = useState([]);
  const addToOrderedFloors = (floor) => {
    if (!orderedFloors.includes(floor)) {
      setOrderedFloors([...orderedFloors, floor]);
      setAvailableFloors(availableFloors.filter((f) => f !== floor));
    }
  };

  const removeFromOrderedFloors = (floor) => {
    setOrderedFloors(orderedFloors.filter((f) => f !== floor));
    setAvailableFloors([...availableFloors, floor]);
  };

  const ListDisplay = ({ title, list }) => {
    if (list.length === 0) return null;
    return (
      <div
        className="floors-list"
        style={{ overflowX: "auto", whiteSpace: "nowrap" }}
      >
        <h2>{title}:</h2>
        <div>
          {list.map((item, index) => (
            <span
              key={index}
              className="textarea-class"
              style={{
                display: "inline-block",
                margin: "1px",
                lineHeight: "1",
                height: "20px",
                width: "13%",
              }}
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <WithNavbar />
      <TopNavbar />

      <div className="upload-container">
        <h2>Digital Twin Viewer Insertion</h2>

        <form onSubmit={handleSubmit}>
          <div>
            <h1>Scene Setup of project</h1>
          </div>

          <div className="file-upload-class">
            <h1>
              Upload Your Project GLB files here(, also you can setup the floor
              order for yourself)
            </h1>
            <ListDisplay
              title="External/Facade  List name retrived from DB"
              list={externalList}
            />
            <ListDisplay
              title="Celing Glb List name retrived from DB"
              list={ceilingList}
            />
            <ListDisplay
              title="Internal Walls  List name retrived from DB"
              list={internalList}
            />

            <ListDisplay
              title="Stairs Glb List name retrived from DB"
              list={stairsList}
            />
            <ListDisplay
              title="Doors Walls  List name retrived from DB"
              list={doorsList}
            />

            <FileUploadSingleComponent
              label="Scene Setup"
              id="project-platform"
              isZip={true}
              onZipContentDetected={handleZipContentDetected}
              onFileSelect={handleFileSelect}
            />
            <div className="floors-management-container">
              <div className="floors-list">
                <h2>Extracted Floor Names:</h2>
                <select multiple size="10" className="textarea-class">
                  {floorOptions.map((floor, index) => (
                    <option
                      key={index}
                      value={floor}
                      onDoubleClick={() => addToOrderedFloors(floor)}
                    >
                      {floor}
                    </option>
                  ))}
                </select>
              </div>
              <div className="floor-buttons">
                <button
                  type="button"
                  onClick={() => {
                    const selectElement = document.querySelector(
                      ".floors-list select"
                    );
                    if (selectElement.selectedOptions.length > 0) {
                      const selectedFloor =
                        selectElement.selectedOptions[0].value;
                      addToOrderedFloors(selectedFloor);
                    }
                  }}
                >
                  Add →
                </button>
                <button
                  type="button"
                  onClick={() => {
                    const selectElement = document.querySelector(
                      ".ordered-floors-list select"
                    );
                    if (selectElement.selectedOptions.length > 0) {
                      const selectedFloor =
                        selectElement.selectedOptions[0].value;
                      removeFromOrderedFloors(selectedFloor);
                    }
                  }}
                >
                  ← Remove
                </button>
              </div>
              <div className="floors-list ordered-floors-list">
                <h2>Ordered Floors:</h2>
                <select multiple size="10" className="textarea-class">
                  {orderedFloors.map((floor, index) => (
                    <option
                      key={index}
                      value={floor}
                      onDoubleClick={() => removeFromOrderedFloors(floor)}
                    >
                      {floor}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div>
            <h2>please insert your floor order</h2>
          </div>
          <div>
            <input
              type="checkbox"
              id="editSceneOrder"
              checked={isEditMode}
              onChange={(e) => setIsEditMode(e.target.checked)}
              title="Just Check this one in case that you need to edit the order of your scene"
            />
            <label htmlFor="editSceneOrder">Edit Scene Order</label>
          </div>

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default SceneSetupInsertion;
