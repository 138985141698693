import React, { useState } from "react";
import "./RegisterUser.css";
import "./Features.css";
import fetchUtil from "./utils/fetchUtil";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopNavbar from "./TopNavbar";

const Feature = ({ title, description, onClick }) => {
  return (
    <div className="feature" onClick={onClick}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};
const Features = () => {
  const features = [
    {
      title: "CREATE NEW DIGITAL TWIN PROJECT",
      description:
        "Here, you can create a project for platform or digital twin viewer",
      //   path: "viewer-insertion",
      path: "new-project",
    },
    {
      title: "EDIT EXISTING DIGITAL TWIN PROJECT",
      description:
        "Here, you can edit an exiting project for platform or digital twin viewer",
      path: "user-projects",
    },
  ];

  const navigate = useNavigate();

  const handleFeatureClick = (feature) => {
    navigate(feature);
  };

  return (
    <div className="container" id="features">
      <h2>Platform Features</h2>
      <div className="features">
        {features.map((feature, index) => (
          <Feature
            key={index}
            title={feature.title}
            description={feature.description}
            onClick={() => handleFeatureClick(`/${feature.path}`)}
          />
        ))}
      </div>
    </div>
  );
};

const ProjectManagement = () => {
  const user = null;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  if (!token) {
    console.log("nadarad");
    return <p>Please login to view this page.</p>;
  }
  console.log(token);

  return (
    <div>
      <TopNavbar />
      <Features />
      {/* <ActivityReport /> */}
    </div>
  );
};

export default ProjectManagement;
