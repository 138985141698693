import React, { useState, useEffect } from "react";

const NotificationWallet = () => {
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setUnreadNotifications((prev) => prev + 1);
      setNotifications((prev) => [
        ...prev,
        { id: Date.now(), text: "New exclusive update available!" },
      ]);
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    if (!showMenu && unreadNotifications > 0) {
      setUnreadNotifications(0);
    }
  };

  return (
    <div className="notification-wallet">
      <button onClick={toggleMenu}>
        Notifications ({unreadNotifications})
      </button>
      {showMenu && (
        <div className="notification-menu">
          <h4>You have new notifications:</h4>
          <ul>
            {notifications.map((notification) => (
              <li key={notification.id}>{notification.text}</li>
            ))}
          </ul>
        </div>
      )}
      {isVisible && (
        <div className="notification-popup">You have new notifications!</div>
      )}
    </div>
  );
};

export default NotificationWallet;
