import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WithNavbar from "./LeftNavbar";
import TopNavbar from "./TopNavbar";
const DeleteUserComponent = () => {
  const [email, setEmail] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const apiRoot = process.env.REACT_APP_API_ROOT;

  const handleDelete = () => {
    if (
      window.confirm(
        `Are you sure you want to delete the user with email ${email}?`
      )
    ) {
      fetch(`${apiRoot}/user/delete/?email=${encodeURIComponent(email)}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            toast.success(
              `User with email ${email} has been deleted successfully.`
            );
            setStatusMessage(
              `User with email ${email} has been deleted successfully.`
            );

            setEmail("");
          } else {
            response.json().then((data) => {
              toast.warn(
                "Please enter an email address. this users was not found."
              );
              // throw new Error(data.error || "Failed to delete the user.");
            });
          }
        })
        .catch((error) => {
          toast.warn("Please enter an email address.");
          setStatusMessage(error.message);
        });
    }
  };

  return (
    <>
      <WithNavbar />
      <TopNavbar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div style={{ width: "300px" }}>
          <input
            type="text"
            placeholder="Enter email to delete..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
          />
          <button
            onClick={handleDelete}
            disabled={!email}
            style={{ width: "100%", padding: "10px" }}
          >
            Delete User
          </button>
          {statusMessage && <p>{statusMessage}</p>}
        </div>
      </div>
    </>
  );
};

export default DeleteUserComponent;
